import React from "react"
import PropTypes from "prop-types"
class LeftPanel extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      tabs: {
        'top':{
          name: null,
          selected: false,
          url: null
        },
        'home':{
          name: 'Home',
          selected: false,
          url: '/',
          icon: 'house'
        },
        'notifications':{
          name:'Notifications',
          selected: false
        },
        'money':{
          name:'TRANSFERS',
          selected: false
        },
        'invoices':{
          name:'Invoices',
          selected: false,
          url: '/invoices/index',
          icon: 'file-invoice'
        },
        'bankaccounts':{
          name:'Bank Accounts',
          selected: false,
          icon: 'building-columns',
          url: '/bankaccounts'
        },
        'transfers':{
          name: 'Transfers',
          selected: false,
          url: '/transfers/rootindex'
        },
        'customers':{
          name:'Customers',
          selected: false,
          icon: 'users',
          url: '/customers/index'
        },
        'funds':{
          name:'Funds',
          selected: false,
          icon: 'filter-circle-dollar',
          url: '/funds/index'
        },
        'commerce':{
          name:'COMMERCE',
          selected: false
        },
        'store':{
          name:'Store',
          selected: false,
          icon: 'store'
        },
        'orders':{
          name:'Orders',
          selected: false,
          icon: 'cart-shopping'
        },
        'devtop':{
          name:'DEVELOPER',
          selected: false
        },
        'developer':{
          name: 'Developer',
          selected: false,
          icon: 'code',
          url: '/developer'
        },
        'webhooks':{
          name: 'Webhooks',
          selected: false,
          url: '/webhooks/index'
        },
        'admin':{
          name:'ADMIN',
          selected: false
        },
        'account':{
          name:'Account',
          selected: false,
          icon: 'circle-user',
          url: '/account'
        },
        'users':{
          name:'Users',
          selected: false,
          url: '/users/index'
        },
        'billing':{
          name:'Billing',
          selected: false
        },
        'apikeys':{
          name: 'API Keys',
          selected: false,
          url: '/developer/apikeys'
        },
        'apidocs':{
          name: 'API Documentation',
          selected: false,
          url: 'https://docs.paysolve.com.au/file/api_v1_documentation.html'
        },
        'help':{
          name: 'Help and Support',
          selected: false,
          icon: 'life-ring',
          url: '/help'
        },
        'newinvoice':{
          name: 'Create',
          selected: false,
          url: '/invoices/new'
        },
        'invoicesearch':{
          name: 'Search',
          selected: false,
          url: '/invoices/search'
        },
        'newfund':{
          name: 'Create',
          selected: false,
          url: '/funds/new'
        },
        'fundsearch':{
          name: 'Search',
          selected: false,
          url: '/funds/search'
        },
        'invoiceupload':{
          name: 'Upload',
          selected: false,
          url: '/invoices/upload'
        },
        'newcustomer':{
          name: 'Create',
          selected: false,
          url: '/customers/new'
        },
        'customersearch':{
          name: 'Search',
          selected: false,
          url: '/customers/search'
        },
        'objects':{
          name: 'OBJECTS',
          selected: false
        },
        'series':{
          name: 'Series',
          selected: false,
          url: '/series/index',
          icon: 'link'
        },
        'newseries':{
          name: 'Create',
          selected: false,
          url: '/series/new'
        },
        'seriessearch':{
          name: 'Search',
          selected: false,
          url: '/series/search'
        },
        'seriesupload':{
          name: 'Upload',
          selected: false,
          url: '/series/upload'
        },
        'consents':{
          name: 'Consents',
          selected: false,
          url: '/consents'
        },
        'redresses':{
          name: 'Redresses',
          selected: false,
          url: '/redresses/index',
          icon: 'clock-rotate-left'
        },
        'redressfiles':{
          name: 'ABA Files',
          selected: false,
          url: '/redressfiles/index'
        }
      },
      tree: [
        {
          code: 'top',
          children: [{
            code: 'home',
            children: []
          }/*,{
            code: 'notifications',
            children: []
          }*/]
        },{
          code: 'objects',
          children: [{
            code: 'invoices',
            children: [{
              code: 'newinvoice',
              children: []
            },{
              code: 'invoicesearch',
              children: []
            },{
              code: 'invoiceupload',
              children: []
            }]
          },{
            code: 'customers',
            children: [{
              code: 'newcustomer',
              children: []
            },{
              code: 'customersearch',
              children: []
            }]
          },/*{
            code: 'funds',
            children: [{
              code: 'newfund',
              children: []
            },{
              code: 'fundsearch',
              children: []
            }]
          },*/{
            code: 'series',
            children: [{
              code: 'newseries',
              children: []
            },{
              code: 'seriessearch',
              children: []
            },{
              code: 'seriesupload',
              children: []
            }]
          }]
        },{
          code: 'money',
          children: [{
            code: 'bankaccounts',
            children: [{
              code: 'consents',
              children: []
            },{
              code: 'transfers',
              children: []
            }]
          },{
            code: 'redresses',
            children: [{
              code: 'redressfiles',
              children: []
            }]
          }]
        },
        /*{
          code: 'commerce',
          children: [{
            code: 'store',
            children: []
          },{
            code: 'orders',
            children: []
          }]
        },*/{
          code: 'devtop',
          children: [{
            code: 'developer',
            children: [{
              code: 'apikeys',
              children: []
            },{
              code: 'apidocs',
              children: []
            },{
              code:'webhooks',
              children: []
            }]
          }]
        },{
          code: 'admin',
          children: [{
            code: 'account',
            children: [/*{
              code: 'users',
              children: []
            },{
              code:'billing',
              children: []
            }*/]
          },{
            code:'help',
            children: []
          }]
        }
      ],
      urls:{
        '/':['home'],
        '/users/index':['account','users'],
        '/invoices/index':['invoices'],
        '/invoices/new':['invoices','newinvoice'],
        '/invoices/search':['invoices','invoicesearch'],
        '/invoice/:param':['invoices'],
        '/invoice/:param/admin':['invoices'],
        '/invoice/:param/edit':['invoices'],
        '/invoices/upload':['invoices','invoiceupload'],
        '/funds/new':['funds','newfund'],
        '/funds/index':['funds'],
        '/funds/search':['funds','fundsearch'],
        '/fund/:param':['funds'],
        '/customers/index':['customers'],
        '/customers/new':['customers','newcustomer'],
        '/customer/:param':['customers'],
        '/customer/:param/admin':['customers'],
        '/customers/search':['customers','customersearch'],
        '/account':['admin','account'],
        '/bankaccounts':['bankaccounts'],
        '/consents':['bankaccounts','consents'],
        '/series/index':['series'],
        '/series/new':['series','newseries'],
        '/series/search':['series','seriessearch'],
        '/series/upload':['series','seriesupload'],
        '/ser/:param/edit':['series'],
        '/ser/:param':['series'],
        '/ser/:param/admin':['series'],
        '/transfers/index/:param':['bankaccounts','transfers'],
        '/transfers/rootindex':['bankaccounts','transfers'],
        '/developer':['developer'],
        '/developer/apikeys':['developer','apikeys'],
        '/webhooks/index':['developer','webhooks'],
        '/redresses/index':['redresses'],
        '/redressfiles/index':['redresses','redressfiles'],
        '/account/:param/admin': ['bankaccounts'],
        '/help': ['help']
      }
    };

    this.MAIN_COLOUR = '#040dba';
    this.SECONDARY_COLOUR = '#32bfb8';//'#26d463';
    this.URL_KEYWORDS = ['invoice','invoices','new','edit','admin','index','search','funds','fund','upload','customer','customers','account','bankaccounts','transfers','rootindex','consents','series','ser','developer','apikeys','webhooks','redress','redresses','redressfiles','help'];

  }

  componentDidMount(){
    let tabs = this.state.tabs;
    const mod_pathname = window.location.pathname.split('/').map((x, i) => i === 0 ? '' : (!this.URL_KEYWORDS.includes(x) ? ':param' : x)).join('/'); // previously the test was x.length > 15
    console.log('mod_pathname');
    console.log(mod_pathname);
    let selected_tabs = this.state.urls[mod_pathname];
    if (selected_tabs === undefined){
      // lift state up to Header.js to remove this and... change header colour?
    } else {
      selected_tabs.forEach((tab) => tabs[tab].selected = true);
    }
    /*tabs['developer'].selected = true;
    tabs['apikeys'].selected = true;*/
    this.setState({
      tabs: tabs
    });
  }

  render () {
    return (
      <div style={{marginTop: '20px'}}>
        {this.state.tree.map(
          (tab1, index1) =>
          <div key={index1}>
            <div style={{color: '#7a7fe6', fontSize: '12px', fontWeight: 'bold', paddingLeft: '10px', marginTop: '12px', cursor: 'default', marginBottom: '3px'}}>
              {this.state.tabs[tab1.code] === undefined ? null : this.state.tabs[tab1.code].name}
            </div>
            {tab1.children.map(
              (tab2, index2) =>
              <div key={index2}>
                <div style={{color: this.state.tabs[tab2.code].selected ? '#fff' : '#fff', /*backgroundColor: this.state.tabs[tab2.code].selected ? this.SECONDARY_COLOUR : this.MAIN_COLOUR,*/ padding: '6px', fontSize: '13px', margin: '4px', marginTop: '0px', borderRadius: '5px', fontWeight: 'bold', cursor: 'pointer'}} className={this.state.tabs[tab2.code].selected ? "left-panel-item-selected" : "left-panel-item"} onClick={() => this.state.tabs[tab2.code].url === undefined ? null : (location.href = this.state.tabs[tab2.code].url)}>
                  <table><tbody>
                    <tr>
                      <td style={{width: '26px', textAlign: 'center', fontSize: '16px'}}>
                        {this.state.tabs[tab2.code] === undefined ? null : (this.state.tabs[tab2.code].icon === undefined ? null : <i className={"fa-solid fa-"+this.state.tabs[tab2.code].icon}></i>)}
                      </td>
                      <td>
                        {this.state.tabs[tab2.code] === undefined ? null : this.state.tabs[tab2.code].name} {tab2.name}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
                {this.state.tabs[tab2.code].selected ? tab2.children.map(
                  (tab3, index3) => 
                  <div style={{color: this.state.tabs[tab3.code].selected ? '#fff' : '#fff', /*backgroundColor: this.state.tabs[tab3.code].selected ? '#fff' : this.MAIN_COLOUR,*/ padding: '5px', paddingLeft: '8px', fontSize: '12px', margin: '2px', marginRight:'4px', marginLeft:'20px', marginTop: '0px', borderRadius: '5px', cursor: 'pointer'}} key={index3} className={this.state.tabs[tab3.code].selected ? "left-panel-item-selected" : "left-panel-item"} onClick={() => this.state.tabs[tab3.code].url === undefined ? null : (location.href = this.state.tabs[tab3.code].url)}>
                    {this.state.tabs[tab3.code] === undefined ? null : this.state.tabs[tab3.code].name}
                  </div>
                ) : null}
              </div>
            )}
          </div>
        )}
        {/*this.state.tabs.map(
          (tab, index) =>
          <div style={{backgroundColor: '#1d25cc', color: '#fff', padding: '14px', fontSize: '14px', margin: '4px', borderRadius: '5px', fontWeight: 'bold', cursor: 'pointer'}} key={index}>
            {tab}
          </div>
        )*/}
      </div>
    );
  }
}

export default LeftPanel
