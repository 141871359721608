import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
class UploadSeries extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 10;
    this.state = {
      data_raw: "",
      data_arr: null,
      data_obj: null,
      filename: "",
      processing: false,
      messages: [],
      showing_page: 0,
      num_pages: null,
      max_num_pages: 5,
      status: 0,
      presend_review: {
        successes: 0,
        errors: 0
      },
      show_confirmation: false,
      result: {
        successes: 0,
        errors: 0
      }
    };

    this.handle_upload = this.handle_upload.bind(this);
    this.keyval_parse = this.keyval_parse.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.array_to_objarray = this.array_to_objarray.bind(this);
    this.csv_to_array = this.csv_to_array.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.parse_response = this.parse_response.bind(this);
    this.send_data = this.send_data.bind(this);
    this.result_csv = this.result_csv.bind(this);
    this.send_data_segment = this.send_data_segment.bind(this);
    this.idempotency_duplicate_search = this.idempotency_duplicate_search.bind(this);
    this.binary_search_string_arr = this.binary_search_string_arr.bind(this);
    this.general_presend_review = this.general_presend_review.bind(this);
    //this.perform_lookup = this.perform_lookup.bind(this);
//:amount, :has_due_date, :due_at, :title, :description, :billing_name, :billing_email, :date_accrued_at, :privacy_status, :passkey_ciphertext, :ref_preference, :remember_viewers, :hide_at_completion, :show_payid, :account_id, :series_id, :idempotency)

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.VALID_HEADERS = [
      "billing_email",
      "billing_name",
      "description",
      "idempotency",
      "passkey_ciphertext",
      "privacy_status",
      "recipient",
      "title",
      "passkey"
    ];
    this.UPLOAD_HEADERS = [
      "billing_email",
      "billing_name",
      "description",
      "idempotency",
      "passkey_ciphertext",
      "privacy_status",
      "recipient",
      "title",
      "passkey"
    ];
    this.FIELD_LENGTH_LIMITS = {
      "description": 500,
      "title": 40,
      "recipient":50,
      "idempotency": 32,
      "passkey": 16,
      "billing_name":50,
      "passkey":20
    }
    this.GENERAL_FIELD_LIMIT = 30;
    this.SERIES_UPLOAD_LIMIT = 10;
  }

  handle_upload(e){
    console.log(e);
    this.setState({
      data_raw: null,
      data_arr: null,
      data_obj: null,
      filename: e.target.files[0].name,
      processing: true,
      showing_page: 0
    }, () => {
      e.target.files[0].text().then(
        (v) => {
          if (e.target.files[0].name.split('.').slice(-1) != "csv"){
            this.add_message("Not a CSV file.",'danger');
            return null;
          } else if (!/^(?:(?:"(?:[^"]|"")*"|[^,\r\n]*)(?:,(?:"(?:[^"]|"")*"|[^,\r\n]*))*[\r\n]*?)*$/.test(v)){
            this.add_message("Not a valid CSV format.",'danger');
            return null;
          }
          this.setState({
            data_raw: v
          }, () => {
            this.setState({
              data_arr: this.csv_to_array(this.state.data_raw, this.state.delimiter)
            }, () => {
              const prcres = this.array_to_objarray();
              if (prcres != null){
                this.add_message("Processing error: "+prcres+" (file not uploaded)", 'danger');
              }
              this.setState({
                processing: false
              });
            });
          });
        }
      );
    });
  }

  keyval_parse(key, val){
    if (key === "privacy_status"){
      if (val === "NO_PASSKEY"){
        return [false, 0];
      } else if (val === "HAS_PASSKEY") {
        return [false, 1];
      } else {
        return [true, "Invalid privacy status."];
      }
    } else if (key === "passkey") {
      if (/^[a-zA-Z0-9]*$/.test(val) && val.length <= this.FIELD_LENGTH_LIMITS["passkey"]){
        return [false, val];
      } else {
        return [true, "Passkey must be alphanumeric and not less than "+this.FIELD_LENGTH_LIMITS["passkey"]+" characters."];
      }
    } else {
      if (key in this.FIELD_LENGTH_LIMITS && val.length <= this.FIELD_LENGTH_LIMITS[key]){
        return [false, val];
      } else if (val.length <= this.GENERAL_FIELD_LIMIT){
        return [false, val];
      } else {
        return [true, key+" violated length constraints."];
      }
    }
  }

  binary_search_string_arr(arr, tgt){
    let l = 0;
    let r = arr.length - 1;
    let m, mv;
    while (true){
      m = Math.floor((l+r)/2);
      mv = arr[m];
      if (mv == tgt){
        return m;
      } else if (l >= r){
        break;
      } else if (mv < tgt){
        l = m + 1;
      } else {
        r = m - 1;
      }
    }
    return -1;
  }

  general_presend_review(){
    let e = 0;
    let i = 0;
    for (i = 0 ; i < this.state.data_obj.length ; i++){
      if (this.state.data_obj[i].result_code != 0){
        e++;
      }
    }
    let presend_review = this.state.presend_review;
    presend_review.successes = this.state.data_obj.length - e;
    presend_review.errors = e;
    this.setState({
      presend_review: presend_review
    });
  }

  idempotency_duplicate_search(){
    let idems = this.state.data_obj.map((o) => o.series.idempotency);
    idems.sort();
    let dups = [...new Set(idems.filter((p,i) => p === idems[i+1]))]
    console.log('dups');
    console.log(dups);
    let data_obj = this.state.data_obj;
    let i;
    for (i = 0 ; i < data_obj.length ; i++){
      if (this.binary_search_string_arr(dups, data_obj[i].series.idempotency) >= 0 && ![null,undefined].includes(data_obj[i].series.idempotency)){
      //if (dups.includes(data_obj[i].invoice.idempotency)){
        data_obj[i].result = "Duplicate idempotency key.";
        data_obj[i].result_code = 1;
      }
    }
    this.setState({
      data_obj: data_obj
    }, () => {
      this.general_presend_review();
    });
    /*let dups = [...new Set(idems.filter((p,i) => p === idems[i+1]))];
    console.log(dups);
    let data_obj = this.state.data_obj;
    dups.forEach((d) => data_obj.forEach((i) => ))*/
  }

  array_to_objarray(){
    const arr = this.state.data_arr;
    const headers = arr[0];
    let errors = [];
    let res = [undefined, undefined];
    if (this.VALID_HEADERS.every((e) => headers.includes(e)) && headers.length === this.VALID_HEADERS){//(JSON.stringify(headers) != JSON.stringify(this.VALID_HEADERS)){
      return "Headers are not valid.";
    }
    // other tests here
    this.setState({
      data_obj: arr.slice(1,arr.length+1).map((l) => {
        if (l.length < 3){ // if it's a nothing line
          return null;
        }
        let obj = {series: {}, auxiliary: {}, result_code: 0};
        let i = 0;
        for (i = 0 ; i < l.length ; i++){
          if (this.UPLOAD_HEADERS.includes(headers[i])){
            res = this.keyval_parse(headers[i],l[i]);
            console.log(res);
            if (res[0]){
              obj['result'] = "Error found: "+res[1];
              obj['result_code'] = 1;
              errors.push("Error on row "+String(i+1)+": "+res[1]);
              break;
            } else {
              obj['series'][headers[i] === "passkey" ? "passkey_ciphertext" : headers[i]] = res[1];//l[i];
            }
          } else if (['tags'].includes(headers[i])) {
            obj['auxiliary'][headers[i]] = l[i];
          }
        }
        obj['result'] = (obj['result'] === undefined ? (headers.length === l.length ? "Checked OK, not created." : "Insufficient columns.") : obj['result']);
        return obj;
      }).filter((k) => k != null)
    }, () => {
      this.idempotency_duplicate_search();
      this.setState({
        num_pages: Math.ceil(this.state.data_obj.length / this.RESULTS_PER_PAGE),
        status: 1
      });
      //this.perform_lookup();
      this.add_message("Successfully uploaded "+String(this.state.data_obj.length)+" Series rows.",'success');
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  csv_to_array( strData, strDelimiter ){
		strDelimiter = (strDelimiter || ",");
		var objPattern = new RegExp(
			(
				"(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +
				"(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +
				"([^\"\\" + strDelimiter + "\\r\\n]*))"
			),
			"gi"
			);
		var arrData = [[]];
		var arrMatches = null;
		while (arrMatches = objPattern.exec( strData )){
			var strMatchedDelimiter = arrMatches[ 1 ];
			if (
				strMatchedDelimiter.length &&
				(strMatchedDelimiter != strDelimiter)
				){
				arrData.push( [] );
			}
			if (arrMatches[ 2 ]){
				var strMatchedValue = arrMatches[ 2 ].replace(
					new RegExp( "\"\"", "g" ),
					"\""
					);

			} else {
				var strMatchedValue = arrMatches[ 3 ];
			}
			arrData[ arrData.length - 1 ].push( strMatchedValue );
		}
		return( arrData );
	}

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  parse_response(sent, received){
    // just in case the response is not in order
    let idem2ind = {};
    sent.forEach((i) => {idem2ind[i.series.idempotency] = i.index});
    let data_obj = this.state.data_obj;
    let result = this.state.result;
    console.log("Parsing received");
    received.forEach(
      (r) => {
        console.log(r);
        if (r.success){
          result.successes += 1;
        } else {
          result.errors += 1;
        }
        data_obj[idem2ind[r.idempotency]].result_code = (r.success ? 2 : 3);
        data_obj[idem2ind[r.idempotency]].result = r.result;
        data_obj[idem2ind[r.idempotency]].code = r.code;
      });
    this.setState({
      result: result,
      data_obj: data_obj
    });
  }

  send_data_segment(series, start, end){
    console.log("Sending data segment");
    console.log(series);
    fetch("/api/series/upload", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({series: series.slice(start, end)})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log('data segment sent');
      console.log(json);
      this.parse_response(series.slice(start, end), json.series);
      if (end < series.length){
        this.send_data_segment(series, start + this.SERIES_UPLOAD_LIMIT, end + this.SERIES_UPLOAD_LIMIT);
      } else {
        this.add_message("Completed upload.",'success');
        this.setState({
          status: 3, // complete
          processing: false
        });
      }
    });
  }

  send_data(){
    //let data_obj = this.state.data_obj;
    this.setState({
      show_confirmation: false,
      status: 2,
      processing: true
    });
    //let data_obj;
    let series = this.state.data_obj.map((d,j) => ({io:d,index:j})).filter((o) => o.io.result_code === 0).map((i) => ({series: i.io.series, auxiliary: i.io.auxiliary, index: i.index}));
    this.send_data_segment(series, 0, this.SERIES_UPLOAD_LIMIT);
  }

  result_csv(){
    this.setState({
      processing: true
    });
    const headers = Object.keys(this.state.data_obj[0].series).concat(['result','code']);
    let csv = "data:text/csv;charset=utf-8,"+headers.join(',')+"\n";
    this.state.data_obj.forEach(
      (i, j) => {
        console.log(Object.values(i.series));
        csv += ([Object.values(i.series).join(','), [i.result, i.code].join(',')].join(',') + "\n"); // it's forgetting a ',' somewhere
      }
    )
    let encoded_uri = encodeURI(csv);
    //window.open(encoded_uri);
    var link = document.createElement("a");
    link.setAttribute("href", encoded_uri);
    link.setAttribute("download", "series-"+String(Date.now())+".csv");
    document.body.appendChild(link);
    link.click();
    this.setState({
      processing: false
    });
  }

  render () {
    console.log(this.state);
    return (
      <div className="form-section" style={{width: '800px'}}>

        {this.state.show_confirmation ? 
          <Overlay on_cancel={() => this.setState({show_confirmation: false})}>
            <div>
              <div>
                Are you sure you want to submit {this.state.presend_review.successes} Series?
              </div>
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_confirmation: false})}>
                      Cancel
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={this.send_data}>
                      Confirm
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h2>
          Create Series
        </h2>

        <div>
          {this.state.status === 0 ?
            <div>
              <h3>
                Select a CSV file for upload
              </h3>
              <button className="std-button" onClick={() => document.getElementById('input').click()}>
                Choose File
              </button>
            </div> : null}
          {this.state.status === 1 ? 
            <div>
              <h3>
                Review file for submission
              </h3>
              <table style={{width: '100%'}}><tbody>
                <tr>
                  <td style={{width: '40%', verticalAlign: 'top'}}>
                    <h4>
                      File
                    </h4>
                    <div style={{color: 'grey', fontSize: '15px', paddingBottom: '20px'}}>
                      {this.state.filename}
                    </div>
                    <button className="std-button" onClick={() => document.getElementById('input').click()}>
                      Choose Another File
                    </button>
                  </td>
                  <td style={{width: '60%', verticalAlign: 'top'}}>
                    <h4>
                      Review
                    </h4>
                    <table style={{width: '70%', margin: 'auto', fontFamily: 'Roboto Mono'}}><tbody>
                      <tr>
                        <td style={{width: '70%'}}>
                          OK for submission:
                        </td>
                        <td style={{textAlign: 'right'}}>
                          {this.state.presend_review.successes}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Errors:
                        </td>
                        <td style={{textAlign: 'right'}}>
                          {this.state.presend_review.errors}
                        </td>
                      </tr>
                    </tbody></table>
                    <button className="std-button" onClick={() => this.setState({show_confirmation: true})}>
                      Submit
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div> : null}
          {this.state.status === 2 ?
            <div>
              Completed {this.state.result.successes + this.state.result.errors} out of {this.state.presend_review.successes}.
            </div> : null}
          {this.state.status === 3 ? 
            <div>
              <div>
                Successfully completed.
              </div>
              <button onClick={this.result_csv} className="std-button">
                {this.state.processing ? "Processing..." : "Download results"}
              </button>
            </div> : null}
        </div>
        
        {this.state.data_obj === null ? null : 
          <div>
            <h3>
              Invoices ({this.state.data_obj.length})
            </h3>
            <table className="invoice-upload-table"><tbody>
              <tr>
                <td>
                  Row
                </td>
                <td>
                  Title
                </td>
                <td>
                  Idempotency
                </td>
                <td>
                  Result
                </td>
              </tr>
              {this.state.data_obj.slice(this.state.showing_page * this.RESULTS_PER_PAGE, (this.state.showing_page + 1) * this.RESULTS_PER_PAGE).map(
                (obj, index) => 
                <tr key={index}>
                  <td style={{textAlign: 'right'}}>
                    {this.state.showing_page * this.RESULTS_PER_PAGE + index + 2}
                  </td>
                  <td>
                    {obj.series.title}
                  </td>
                  <td>
                    {obj.series.idempotency}
                  </td>
                  <td style={{maxWidth: '200px', color: obj.result_code % 2 === 1 ? 'red' : 'black'}}>
                    {obj.result}
                  </td>
                </tr>
              )}
            </tbody></table>
          </div>}
          
        {this.state.num_pages != null ? 
          <table className="search-pagination-table"><tbody>
            <tr>
              <td>
                {this.state.showing_page > this.state.max_num_pages / 2 ? 
                  <div onClick={() => this.setState({showing_page: this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1})}>
                    ...
                  </div> : null}
              </td>
              {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                  <td key={index}>
                    <div onClick={() => this.setState({showing_page: index})} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                      {index+1}
                    </div>
                  </td>) : 
                Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                  this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                    <td key={index}>
                      <div onClick={() => this.setState({showing_page: v})} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                        {v + 1}
                      </div>
                    </td>)}
              <td>
                {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                  <div onClick={() => this.setState({showing_page: Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages)})}>
                    ...
                  </div> : null}
              </td>
            </tr>
          </tbody></table> : null}

        <div style={{visibility: 'hidden'}}>
          <input type="file" onChange={this.handle_upload} id="input" />
        </div>

      </div>
    );
  }
}

export default UploadSeries
