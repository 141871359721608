import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
class PayorAbaFile extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      invoices: [],
      loaded: false,
      bsb: "",
      bsb_status: 0,
      bsb_message: "",
      account_number: "",
      account_number_status: "",
      account_number_message: "",
      account_name: "",
      account_name_status: "",
      account_name_message: "",
      card_index: 0,
      pagination_index: 0,
      messages: []
		};

    this.get_due_bills = this.get_due_bills.bind(this);
    this.on_type_bsb = this.on_type_bsb.bind(this);
    this.on_blur_bsb = this.on_blur_bsb.bind(this);
    this.on_type_account_number = this.on_type_account_number.bind(this);
    this.on_blur_account_number = this.on_blur_account_number.bind(this);
    this.on_type_account_name = this.on_type_account_name.bind(this);
    this.on_blur_account_name = this.on_blur_account_name.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.remove_invoice = this.remove_invoice.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.validation_errors = this.validation_errors.bind(this);
    this.download_file = this.download_file.bind(this);
    this.generate_aba_string = this.generate_aba_string.bind(this);
    this.generate_aba_file = this.generate_aba_file.bind(this);

    this.COMPONENT_WIDTH = 600;
    this.RESULTS_PER_PAGE = 5;

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    
  }

  componentDidMount(){
    this.get_due_bills();
  }

  on_type_bsb(e){
    const typed = e.target.value.replace('-','');
    if (typed.length <= 6 && /^\d+$/.test(typed)){
      this.setState({
        bsb: typed,
        bsb_status: typed.length === 6 ? 2 : 1,
        bsb_message: ""
      });
    }
  }

  on_blur_bsb(){
    if (this.state.bsb.length === 6){
      this.setState({
        bsb_status: this.state.bsb.length === 6 ? 2 : 3,
        bsb_message: this.state.bsb.length === 6 ? "" : "Incorrect BSB pattern"
      });
    }
  }

  on_type_account_number(e){
    const typed = e.target.value;
    if (typed.length <= 9 && /^\d+$/.test(typed)){
      this.setState({
        account_number: typed,
        account_number_status: 1
      });
    }
  }

  on_blur_account_number(){
    if (this.state.account_number.length < 32 && /^\d+$/.test(this.state.account_number)){
      this.setState({
        account_number_status: 2,
        account_number_message: ""
      });
    } else {
      this.setState({
        account_number_status: 3,
        account_number_message: "Incorrect account number format"
      });
    }
  }

  on_type_account_name(e){
    const typed = e.target.value;
    this.setState({
      account_name: typed,
      account_name_status: 1
    });
  }

  on_blur_account_name(){
    this.setState({
      account_name_status: this.state.account_name.length > 0 ? 2 : 3,
      account_name_message: "Please enter an account name"
    });
  }

  get_due_bills(){
    // /api/payors/due_bills
    fetch("/api/payors/due_bills", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        invoices: json.invoices,
        bsb: [null,undefined].includes(json.bsb) ? "": json.bsb,
        account_name: [null,undefined].includes(json.account_name) ? "" : json.account_name,
        account_number: [null,undefined].includes(json.account_number) ? "" : json.account_number
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  remove_invoice(index){
    let invoices = this.state.invoices.filter((inv,i) => i != index);
    this.setState({
      invoices: invoices
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  validation_errors(){
    return [];
  }

  generate_aba_string(){
    /* LAST THING: get a lookup of the BSB to mnemonic :/ */
    const user = this.state.account_name.slice(0,26).toUpperCase() + (Array((26 - this.state.account_name.slice(0,26).length)).fill(' ').join(''));
    let str = "0                 01CBA       "+user+"      PS INVOICES "+[(new Date()).getDate(),(new Date()).getMonth()+1,(new Date()).getFullYear()%100].map((d) => (d < 10 ? '0' : '')+String(d)).join('')+"                                        \n";
    str += this.state.invoices.map(
      (invoice, i) => [
        '1',
        invoice.bsb.slice(0,3)+'-'+invoice.bsb.slice(3,6),
        Array(9-invoice.account_number.length).fill(' ').join('') + invoice.account_number,
        ' 50',
        Array(10 - String(invoice.amount_due).length).fill('0').join('') + String(invoice.amount_due),
        invoice.account_name.toUpperCase() + Array(32 - invoice.account_name.length).fill(' ').join(''),
        invoice.ps_identifier + Array(18 - invoice.ps_identifier.length).fill(' ').join(''),
        this.state.bsb.slice(0,3)+'-'+this.state.bsb.slice(3,6),
        Array(9 - String(invoice.account_number).length).fill(' ').join('') + this.state.account_number,
        this.state.account_name.slice(0,16).toUpperCase() + Array(16 - this.state.account_name.slice(0,16).length).fill(' ').join(''),
        '00000000'
      ].join('')
    ).join("\n");
    const total = String(this.state.invoices.reduce((a,i) => i.amount_due + a, 0));
    const total_records = String(this.state.invoices.length);
    str += "\n7999-999            "+[Array(10-total.length).fill('0').join('')+total,'0000000000',Array(10-total.length).fill('0').join('')+total].join('')+"                        "+Array(6 - total_records.length).fill('0').join('')+total_records+"                                        ";
    return str;
  }

  download_file(content, filename, mimeType = 'text/plain') {
    // Create a Blob from the content
    const blob = new Blob([content], { type: mimeType });
  
    // Create a temporary link element
    const link = document.createElement('a');
  
    // Set the download attribute with the filename
    link.download = filename;
  
    // Create an object URL for the Blob and set it as the href
    link.href = URL.createObjectURL(blob);
  
    // Programmatically trigger the link click to start the download
    link.click();
  
    // Clean up by revoking the object URL
    URL.revokeObjectURL(link.href);
  }

  generate_aba_file(){
    const errors = this.validation_errors();
    if (errors.length > 0){
      return this.add_message("Errors found: "+errors.join(','),'danger');
    }
    const aba_file_string = this.generate_aba_string();
    this.download_file(aba_file_string, 
      'PS_ABA_'+(new Date()).toISOString().split(/[-T.:]/).slice(0,6).join('')+'.aba');
  }

  render () {
    console.log("PayorAbaFile state:");
    console.log(this.state);
    return (
      <div>

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <div>
          <span style={{color: 'grey'}}><b>Summary:</b></span> {this.state.invoices.length} Invoice{this.state.invoices.length != 1 ? 's' : ''} totalling ${this.amount_to_formatted_string(this.state.invoices.reduce((a,i) => i.amount_due + a, 0))}
        </div>
        <table className="payment-header"><tbody>
          <tr>
            <td onClick={() => this.setState({card_index: 0})} style={{color: this.state.card_index === 0 ? '#040dba' : 'grey'}}>
              Your Account
            </td>
            <td onClick={() => this.setState({card_index: 1})} style={{color: this.state.card_index === 1 ? '#040dba' : 'grey'}}>
              Invoices to be paid ({this.state.invoices.length})
            </td>
          </tr>
        </tbody></table>
        <div className="payment-slider" style={{borderTop: 'solid', borderColor: '#040dba', width: (this.COMPONENT_WIDTH / 2)+'px', borderWidth: '3px', marginLeft: Math.min(this.state.card_index, 2) * (this.COMPONENT_WIDTH / 2) + 'px'}} />

        {[
          <div className="myinput-wrapper-centre">
            <br />
            <MyInput onChange={this.on_type_bsb} onKeyDown={/*this.on_key_down_email*/null} value={this.state.bsb.length > 3 ? this.state.bsb.slice(0,3)+'-'+this.state.bsb.slice(3,6) : this.state.bsb} status={this.state.bsb_status} note={this.state.bsb_status === 3 ? this.state.bsb_message : null} label="BSB" onBlur={this.on_blur_bsb} />
            <MyInput onChange={this.on_type_account_number} value={this.state.account_number} status={this.state.account_number_status} onBlur={this.on_blur_account_number} onFocus={() => this.setState({account_number_status: 1})} label="Account Number" note={this.state.account_number_status === 3 ? this.state.account_number_message : null} />
            <MyInput onChange={this.on_type_account_name} value={this.state.account_name} status={this.state.account_name_status} onBlur={this.on_blur_account_name} onFocus={() => this.setState({account_name_status: 1})} label="Account Name" note={this.state.account_name_status === 3 ? this.state.account_name_message : null} />
          </div>,
          
          <div>
            <table className="redress-result-table"><tbody>
              <tr>
                <th>
                  Title
                </th>
                <th>
                  Amount
                </th>
                <th>
                  BSB
                </th>
                <th>
                  Acct Num
                </th>
                <th>
                  Remove
                </th>
              </tr>
              {this.state.invoices.slice(this.RESULTS_PER_PAGE * this.state.pagination_index, this.RESULTS_PER_PAGE * (this.state.pagination_index + 1)).map(
                (invoice, index) => 
                <tr key={index}>
                  <td>
                    {invoice.title}
                  </td>
                  <td style={{textAlign: 'right'}}>
                    ${this.amount_to_formatted_string(invoice.amount_due)}
                  </td>
                  <td>
                    {invoice.bsb}
                  </td>
                  <td>
                    {invoice.account_number}
                  </td>
                  <td>
                    <button className="button-blue" onClick={() => this.remove_invoice(index)}>
                      Remove
                    </button>
                  </td>
                </tr>
              )}
            </tbody></table>
            <table style={{margin: 'auto'}}><tbody>
              <tr>
                <td>
                  <div onClick={() => this.setState({pagination_index: Math.max(this.state.pagination_index - 1,0)})} style={{width: '30px', height: '25px', borderRadius: '4px', textAlign: 'center', backgroundColor: '#efefef', paddingTop: '5px', cursor: 'pointer'}}>
                    <i className="fa-solid fa-arrow-left"></i>
                  </div>
                </td>
                <td style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '14px', fontFamily: 'Roboto Mono'}}>
                  Page {this.state.pagination_index + 1} of {Math.ceil(this.state.invoices.length / this.RESULTS_PER_PAGE)}
                </td>
                <td>
                  <div onClick={() => this.setState({pagination_index: Math.min(this.state.pagination_index + 1, Math.ceil(this.state.invoices.length / this.RESULTS_PER_PAGE) - 1)})} style={{width: '30px', height: '25px', borderRadius: '4px', textAlign: 'center', backgroundColor: '#efefef', paddingTop: '5px', cursor: 'pointer'}}>
                    <i className="fa-solid fa-arrow-right"></i>
                  </div>
                </td>
              </tr>
            </tbody></table>
          </div>
        ][this.state.card_index]}

        <div style={{textAlign: 'center'}}>
          <button className="std-button" onClick={this.generate_aba_file}>
            Get ABA File
          </button>
        </div>
      </div>
    );
  }
}

export default PayorAbaFile
