import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"

class DeleteInvoices extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed_codes: "",
      status: 0,
      invoices: [],
      error_count: 0,
      code_to_obj: {}
    };

    this.parse_typed_codes = this.parse_typed_codes.bind(this);
    this.lookup_codes = this.lookup_codes.bind(this);

  }

  parse_typed_codes(){
    let code_to_obj = {};
    let invoices = [];
    this.state.typed_codes.split(',').forEach(
      (code, index) => {
        let obj = {code: code};
        let error_count = 0;
        if (code.slice(0,4) !== "INV_"){
          obj.pass = false;
          obj.lookup = false;
          obj.lookup_error = false;
          obj.delete_success = false;
          obj.delete_error = null;
          obj.error = "Improper code format";
          obj.code = code;
          error_count += 1;
        } else {
          obj.pass = true;
          obj.error = null;
          obj.lookup = false;
          obj.delete_success = false;
          obj.delete_error = null;
          obj.code = code;
          obj.lookup_error = false;
        }
        code_to_obj[code] = obj;
        invoices.push(obj);
      }
    );
    this.setState({
      code_to_obj: code_to_obj,
      invoices: invoices
    }, () => {
      this.lookup_codes();
    });
  }

  lookup_codes(){
    let codes = this.state.invoices.filter((v) => v.pass).map((i) => i.code);
    console.log("codes");
    console.log(codes);
    fetch("/api/code_lookup", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({invoices: codes, accounts: [], series: []})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      json.codes.forEach()
    });
  }

  submit_delete(){
    fetch("/api/code_lookup", {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({invoices: codes, accounts: [], series: []})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
    });
  }

  render () {
    return (
      <div className="form-section" style={{width: '800px'}}>
        {this.state.status > 0 ? 
          <Overlay on_cancel={() => this.setState({status: 0})}>
            {[
              null,
              <div>
                Searching for Invoices
              </div>,
              <div></div>
            ][this.state.status]}
          </Overlay> : null}
        <h2>
          Delete Invoices
        </h2>
        <p>
          Separate Invoice codes with commas.
        </p>
        <div style={{textAlign: 'center'}}>
          <textarea className="standard-input" style={{borderColor: '#040dba', resize: 'none', width: '600px'}} onChange={(e) => this.setState({typed_codes: e.target.value})}>
          </textarea>
        </div>
        <button className="std-button" onClick={this.parse_typed_codes}>
          Progress
        </button>
      </div>
    );
  }
}

export default DeleteInvoices
