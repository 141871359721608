import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import Checkbox from "./Checkbox.js"
import MyInput from "./MyInput.js"
class AdminSeries extends React.Component {
  constructor(props){
    super(props);
    this.RESULTS_PER_PAGE = 5;
    this.state = {
      init_loaded: false,
      loading: false,
      messages: [],
      series: null,
      show_search_invoices: false,
      include_billing_email: false,
      billing_email: "",
      include_code: true,
      code: "",
      include_tags: false,
      typed_tag: "",
      tags: [],
      searching: false,
      show_results: false,
      results: [],
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 10,
      num_pages: null,
      showing_page: 0,
      invoice_list_index: -1,
      show_delete: false,
      typed_delete_code: "",
      typed_delete_code_status: 0,
      typed_delete_code_message: ""
    };

    this.retrieve_details = this.retrieve_details.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.assign_invoice = this.assign_invoice.bind(this);
    this.remove_invoice = this.remove_invoice.bind(this);
    this.search_invoices = this.search_invoices.bind(this);
    this.on_type_tag = this.on_type_tag.bind(this);
    this.on_key_down_tag = this.on_key_down_tag.bind(this);
    this.update_tags = this.update_tags.bind(this);
    this.remove_tag = this.remove_tag.bind(this);
    this.toggle_url_passkey_inclusion = this.toggle_url_passkey_inclusion.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.admin_update = this.admin_update.bind(this);
    this.confirm_delete = this.confirm_delete.bind(this);
    this.retrieve_invoices = this.retrieve_invoices.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};
    this.MIN_TAG_LENGTH = 4;
    this.SERIES_STATUS_TO_WORD = {'0':'INITIATE','1':'ACTIVE','3':'SUSPEND','-1':'SUSPEND'};

  }

  componentDidMount(){
    this.retrieve_details();
  }

  retrieve_details(){
    // /api/invoice/:code
    fetch("/api/ser/"+this.props.series_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        series: json.series,
        init_loaded: true
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  assign_invoice(index){
    // /ser/:code/assign_invoice
    fetch("/api/ser/"+this.state.series.code+"/assign_invoice", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        invoice_code: this.state.results[index].code
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed invoice assignment.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        let results = this.state.results;
        results[index].series_id = results[index].series_id === this.state.series.id ? null : this.state.series.id;
        //this.add_message((results[index].series_id === this.state.series.id ? "Invoice assigned" : "Invoice unassigned"),'success');
        let series = this.state.series;
        if (json.added){
          console.log("Added triggered");
          this.add_message("Invoice assigned",'success');
          series.invoices.push(json.invoice);
        } else {
          console.log("Remove triggered");
          this.add_message("Invoice unassigned",'success');
          series.invoices = series.invoices.map((x,i) => x.code === json.invoice.code ? null : x).filter((y) => y != null);
        }
        series.total_paid = json.total_paid;
        series.total_due = json.total_due;
        console.log("New series");
        console.log(series);
        this.setState({
          results: results,
          series: series
        });
      } else {
        this.add_message("Failed to assign invoice",'danger');
      }
    });
  }

  remove_invoice(invoice_code){
    // /ser/:code/assign_invoice
    fetch("/api/ser/"+this.state.series.code+"/assign_invoice", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        invoice_code: invoice_code
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed invoice assignment.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Invoice unassigned",'success');
        let series = this.state.series;
        series.invoices = series.invoices.map((x,i) => x.code === invoice_code ? null : x).filter((y) => y != null);
        series.total_paid = json.total_paid;
        series.total_due = json.total_due;
        this.setState({
          series: series,
          invoice_list_index: -1
        });
      } else {
        this.add_message("Failed to assign invoice",'danger');
      }
    });
  }

  search_invoices(page,limit,req_count){
    this.setState({
      searching: true
    });
    let body = {invoice:{},offset:page*this.state.results_per_page,limit:limit,req_count:req_count};
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    //['amount','created_at','due_at'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['billing_email','code'].forEach((k,i) => this.state['include_'+k] ? body['invoice'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    body['tags'] = this.state.include_tags ? this.state.tags : [];
    fetch("/api/invoices/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Search failed.",'danger');
      this.setState({
        searching: false
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        searching: false,
        results: json.invoices,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page
      });
    });
  }

  on_type_tag(e){
    this.setState({
      typed_tag: e.target.value
    });
  }

  on_key_down_tag(e){
    if (e.key === 'Enter' && !this.state.series.tags.includes(this.state.typed_tag)){
      this.update_tags([this.state.typed_tag],[]);
    }
  }

  toggle_url_passkey_inclusion(){
    let series = this.state.series;
    if (series.short_url.includes('?pk')){
      series.short_url = series.short_url.split('?pk')[0];
    } else {
      series.short_url += ('?pk='+this.state.series.passkey);
    }
    this.setState({
      series: series
    });
  }

  admin_update(action){
    this.setState({
      loading: true
    });
    // /api/invoice/:code/admin
    fetch("/api/ser/"+this.props.series_code+"/admin", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        'action_name': action
      })
    }).then( (response) => {
      this.setState({
        loading: false
      });
      if (response.ok){
        return response.json();
      }
      this.add_message("An error was encountered", 'danger');
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        /*if (action === 'SET_AS_PAID'){
          this.add_message("Successfully set as paid", 'success');
        } else if (action === 'DELETE'){
          console.log("Successfully deleted.");
          location.href = "/redirect2?redirect_to=%2Finvoices%2Findex&flash_type=success&flash_message=Invoice%20successfully%20deleted.";
        } else if (action === 'VISIBLE'){
          this.add_message("Invoice set as visible", 'success');
        } else if (action === 'INVISIBLE'){
          this.add_message("Invoice set as invisible", 'success');
        } else if (action === 'OPEN'){
          this.add_message("Invoice is visible and can collect payments",'success');
        } else if (action === 'SUSPEND'){
          this.add_message("Invoice has been suspended. It is only visible to you, and cannot collect payments.",'success');
        }*/
        if (action === 'DELETE'){
          console.log("Successfully deleted.");
          location.href = "/redirect2?redirect_to=%2Fseries%2Findex&flash_type=success&flash_message=Series%20successfully%20deleted."
        }
      } else {
        this.add_message("An error was encountered", 'danger');
      }
      this.setState({
        //show_set_as_paid: false,
        //show_status_screen: false,
        series: json.series
      });
    });
  }

  update_tags(add_terms, remove_terms){
    fetch("/api/ser/"+this.state.series.code+"/tag", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        add: add_terms,
        remove: remove_terms
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      let series = this.state.series;
      series.tags.filter((e) => !remove_terms.includes(e));
      series.tags = this.state.series.tags.concat(add_terms);
      this.setState({
        series: series,
        typed_tag: ""
      });
    });
  }

  remove_tag(index){
    this.update_tags([], [this.state.series.tags[index]]);
    let series = this.state.series;
    series.tags.splice(index, 1);
  }

  confirm_delete(){
    console.log(this.state.typed_delete_code);
    console.log(this.state.series.short_url);
    if (this.state.typed_delete_code != this.state.series.short_url){
      this.setState({
        typed_delete_code_status: 3,
        typed_delete_code_message: "Incorrect code"
      });
      return false;
    }
    this.admin_update('DELETE');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  retrieve_invoices(n){
    // /api/ser/:code/invoices
    fetch("/api/ser/"+this.state.series.code+"/invoices?limit="+String(n), {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to retrieve invoices.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      let series = this.state.series;
      series.invoices = json.invoices;
      this.setState({
        series: series
      });
    });
  }

  render () {
    console.log(this.state);
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.show_delete ? 
          <Overlay on_cancel={() => this.setState({show_delete: false})} title="Delete Series">
            <div>
              <p>
                Are you sure you want to delete this Series <b>{this.state.series.title}</b>?
              </p>
              <p>
                To delete, enter the Series code <b>{this.state.series.short_url}</b>:
              </p>
              <div className="myinput-wrapper-centre">
                <MyInput onChange={(e) => this.setState({typed_delete_code: e.target.value, typed_delete_code_status: 1, typed_delete_code_message: ""})} value={this.state.typed_delete_code} status={this.state.typed_delete_code_status} note={this.state.typed_delete_code_message} />
              </div>
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" style={{backgroundColor: 'red'}} onClick={this.confirm_delete}>
                      Delete
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_delete: false})}>
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        {this.state.invoice_list_index >= 0 ?
          <Overlay on_cancel={() => this.setState({invoice_list_index: -1})} title={this.state.series.invoices[this.state.invoice_list_index].title}>
            <div>
              <table className="series-invoice-brief-table"><tbody>
                <tr>
                  <td>
                    Title
                  </td>
                  <td>
                    {this.state.series.invoices[this.state.invoice_list_index].title}
                  </td>
                </tr>
                <tr>
                  <td>
                    Code
                  </td>
                  <td>
                    {this.state.series.invoices[this.state.invoice_list_index].code}
                  </td>
                </tr>
                <tr>
                  <td>
                    Amount
                  </td>
                  <td>
                    ${this.amount_to_formatted_string(this.state.series.invoices[this.state.invoice_list_index].amount)}
                  </td>
                </tr>
              </tbody></table>
              <table><tbody>
                <tr>
                  <td>
                    <a href={"/invoice/"+this.state.series.invoices[this.state.invoice_list_index].code+"/admin"} style={{textDecoration: 'none', color: '#fff'}}>
                      <button className="std-button">
                        Go to
                      </button>
                    </a>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.remove_invoice(this.state.series.invoices[this.state.invoice_list_index].code)}>
                      Remove
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        {this.state.show_search_invoices ? 
          <Overlay on_cancel={() => this.setState({show_search_invoices: false})} title="Add invoices" width="560px">
            <div style={{width: '500px', height: '570px'}}>
              
              {this.state.messages.map(
                (message, index) =>
                <div className={"flash flash-"+message.type} key={index}>
                  <table><tbody>
                    <tr>
                      <td style={{fontSize: '18px'}}>
                        <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                      </td>
                      <td>
                        {message.content}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}

              <table><tbody>
                <tr>
                  <td style={{width: '40px', textAlign: 'center', verticalAlign: 'top', padding: '15px'}}>
                    <Checkbox on_toggle={(v) => this.setState({include_code: v})} init_value={this.state.include_code} />
                  </td>
                  <td>
                    <h3>
                      Search by Code
                    </h3>
                    {this.state.include_code ? 
                      <div>
                        <MyInput type="text" onChange={(e) => this.setState({code: e.target.value})} value={this.state.code} status={0} label="Code" note={null} />
                      </div> : null}
                  </td>
                </tr>
                <tr>
                  <td style={{width: '40px', textAlign: 'center', verticalAlign: 'top', padding: '15px'}}>
                    <Checkbox on_toggle={(v) => this.setState({include_tags: v})} init_value={this.state.include_tags} />
                  </td>
                  <td>
                    <h3>
                      Search by Tags
                    </h3>
                    {this.state.include_tags ? 
                      <div style={{marginTop: '10px'}}>
                        <div>
                          {this.state.tags.map(
                            (tag, index) =>
                            <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold'}}>
                              {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.setState({tags: this.state.tags.filter((v) => v != tag)})}></i>
                            </div>
                          )}
                        </div>
                        <MyInput type="text" onChange={(e) => this.setState({typed_tag: e.target.value})} onKeyDown={(e) => e.code === "Enter" && this.state.typed_tag.length >= this.MIN_TAG_LENGTH ? this.setState({tags: this.state.tags.concat([this.state.typed_tag]), typed_tag: ""}) : null} value={this.state.typed_tag} status={0} label="Tag - press Enter to add" note={null} />
                      </div> : null}
                  </td>
                </tr>
              </tbody></table>
              <button onClick={() => this.search_invoices(0,this.state.limit,true)} className="std-button">
                Search
              </button>
              {this.state.show_results ? 
                <div>
                  <h3>
                    Results
                  </h3>
                  
                  <table className="small-invoice-results"><tbody>
                    <tr>
                      <td>
                        Code
                      </td>
                      <td>
                        Title
                      </td>
                      <td style={{textAlign: 'left'}}>
                        Add/remove
                      </td>
                    </tr>
                    {this.state.results.map(
                      (result, index) => 
                      <tr key={index}>
                        <td>
                          <a href={"/invoice/"+result.code} target="_blank" style={{color: 'black'}}>{result.code}</a>
                        </td>
                        <td>
                          {result.title.slice(0,28)}{result.title.length > 28 ? "..." : null}
                        </td>
                        <td>
                          {result.series_id === this.state.series.id ? 
                            <button onClick={() => this.assign_invoice(index)} className="button-blue">
                              Remove
                            </button> : 
                            <button onClick={() => this.assign_invoice(index)} className="button-blue">
                              Add
                            </button>}
                        </td>
                      </tr>
                    )}
                  </tbody></table>

                  {this.state.total_count != null ? 
                  <table className="search-pagination-table"><tbody>
                    <tr>
                      <td>
                        {this.state.showing_page > this.state.max_num_pages / 2 ? 
                          <div onClick={() => this.search_invoices(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                            ...
                          </div> : null}
                      </td>
                      {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                        Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                          <td key={index}>
                            <div onClick={() => this.search_invoices(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                              {index+1}
                            </div>
                          </td>) : 
                        Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                          this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                            <td key={index}>
                              <div onClick={() => this.search_invoices(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                                {v + 1}
                              </div>
                            </td>)}
                      <td>
                        {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                          <div onClick={() => this.search_invoices(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                            ...
                          </div> : null}
                      </td>
                    </tr>
                  </tbody></table> : null}

                </div> : null}
            </div>
          </Overlay> : null}

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                <span style={{color:'grey'}}>Series </span>{this.state.series.title}
              </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                ${this.amount_to_formatted_string(this.state.series.total_paid)}
              </h2>
            </td>
          </tr>
        </tbody></table>

        <table className="admin-top-table"><tbody>
          <tr>
            <td>
              <div className="grey-cap-title">
                AMOUNT PAID
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.series.total_paid)}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                AMOUNT OWING
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                ${this.amount_to_formatted_string(this.state.series.total_due)}
              </div>
            </td>
            <td>
              <div className="grey-cap-title">
                INVOICES
              </div>
              <div style={{fontSize: '18px', fontWeight: 'bold', textAlign: 'center'}}>
                {this.state.series.invoice_count}
              </div>
            </td>
          </tr>
        </tbody></table>

        <button onClick={() => this.setState({show_search_invoices: true})} className="std-button">
          Invoice search
        </button>
        <button onClick={() => {window.location = "/ser/"+this.props.series_code+"/edit";}} className="std-button">
          Edit Series
        </button>
        <button onClick={() => window.location = "/ser/"+this.props.series_code} className="std-button">
          Go to Series
        </button>

        <table style={{marginTop: '20px'}}><tbody>
          <tr>
            <td style={{width: '300px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                SHORT URL
              </div>
              <div style={{fontWeight: 'bold', fontSize: '18px', fontFamily: 'Roboto Mono'}}>
                invc.me/{this.state.series.short_url}
              </div>
              <div>
                <span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.series.short_url)} className="copy-span">COPY</span>
                {this.state.series.privacy_status === 0 ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.series.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>}
              </div>
            </td>
            <td style={{width: '250px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                STATUS
              </div>
              {[1,2,3,4,5,6].includes(this.state.series.status) || true ? 
                <div>
                  <span style={{fontSize: '15px',fontWeight:'bold'}}>{this.SERIES_STATUS_TO_WORD[this.state.series.status]}</span><br />
                  {/*this.state.invoice.status === 4 ? null : <span onClick={(e) => this.setState({show_status_screen: true})} className="copy-span">CHANGE</span>*/}
                </div> : null}
            </td>
            <td style={{width: '250px', verticalAlign: 'top', textAlign: 'center'}}>
              <div className="grey-cap-title">
                CODE
              </div>
              <div style={{fontSize: '16px', fontWeight: 'bold', fontFamily: 'Roboto Mono'}}>
                {this.state.series.code}
                <div>
                  <span onClick={(e) => this.click_to_copy(e, this.state.series.code)} className="copy-span" style={{fontFamily: 'Inter'}}>COPY</span>
                </div>
              </div>
            </td>
          </tr>
        </tbody></table>

        <h3>
          Tags
        </h3>
        <div style={{backgroundColor: "#efefef", borderRadius: '6px', margin: '5px', minHeight: '40px', padding: '5px'}}>
          {this.state.series.tags.map(
            (tag, index) =>
            <div key={index} style={{padding: '6px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '5px', backgroundColor: '#040dba', color: '#fff', display: 'inline-block', borderRadius: '14px', fontWeight: 'bold', fontFamily: 'Roboto Mono'}}>
              {tag} <i style={{marginLeft: '4px', cursor: 'pointer'}} className="fa-solid fa-circle-xmark" onClick={() => this.remove_tag(index)}></i>
            </div>
          )}
          <div style={{padding: '0px', paddingLeft: '12px', paddingRight: '12px', fontSize: '13px', margin: '0px', display: 'inline-block', borderRadius: '14px'}}>
            <input type="text" style={{backgroundColor: '#efefef', border: 'none', fontFamily: 'Roboto Mono'}} id="tag-input" value={this.state.typed_tag} onChange={this.on_type_tag} onKeyDown={this.on_key_down_tag} placeholder="Type tag here..." />
          </div>
        </div>

        <h3>
          Assigned Invoices
        </h3>
        <table className="series-invoices-table"><tbody>
          <tr style={{color: 'grey'}}>
            <td>
              Code
            </td>
            <td>
              Title
            </td>
            <td>
              Status
            </td>
            <td>
              Amount
            </td>
          </tr>
          {this.state.series.invoices.map(
            (invoice, index) =>
            <tr style={{backgroundColor: index % 2 === 0 ? "#efefef" : "#fff"}} key={index}>
              <td style={{textAlign: 'center'}}>
                <span onClick={() => this.setState({invoice_list_index: index})} style={{textDecoration: 'underline', cursor: 'pointer'}}>{invoice.code}</span>
              </td>
              <td>
                {invoice.title}
              </td>
              <td style={{textAlign: 'center'}}>
                {this.SERIES_STATUS_TO_WORD[String(invoice.status)]}
              </td>
              <td style={{textAlign: 'right'}}>
                ${this.amount_to_formatted_string(invoice.amount)}
              </td>
            </tr>
          )}
        </tbody></table>
        {this.state.series.invoice_count > this.state.series.invoices.length ? 
          <button className="std-button" onClick={() => this.retrieve_invoices(this.state.series.invoices.length + 5)}>
            More
          </button> : null}

        <button className="std-button" style={{backgroundColor: 'red'}} onClick={() => this.setState({show_delete: true})}>
          Delete
        </button>
        
      </div> : 
      <div className="form-section">
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}
        <h2 style={{color: 'grey'}}>
          Series Admin
        </h2>
        <p style={{fontSize: '14px'}}>
          Loading...
        </p>
      </div>
    );
  }
}

export default AdminSeries
