import React from "react"
import PropTypes from "prop-types"
import Checkbox from "./Checkbox.js"
import MyInput from "./MyInput.js"
import Overlay from "./Overlay.js"
class RedressIndex extends React.Component {
  constructor(props){
    super(props);

    this.RESULTS_PER_PAGE = 5;

    this.state = {
      messages: [],

      results: [],
      selected: [],
      search_clicked: false,
      accounts_loaded: false,
      accounts: [],
      account_index: 0,

      include_code: false,
      code: "",
      include_status: false,
      status: [],
      include_created_at: false,
      created_at_max: new Date(Date.now() - Date.now() % 86400000).toISOString(),
      created_at_min: new Date(Date.now() - Date.now() % 86400000 - 7 * 86400000).toISOString(),
      include_transaction_at: false,
      transaction_at_max: new Date(Date.now() - Date.now() % 86400000).toISOString(),
      transaction_at_min: new Date(Date.now() - Date.now() % 86400000 - 7 * 86400000).toISOString(),
      include_request_type: false,
      request_type: [],
      include_redressfile_id: false,
      include_file_code: false,
      file_code: "",
      redressfile_params_result: null,

      show_dismiss_redress: false,

      show_redress: false,
      redress_index: -1,
      redress: null,
      match_index: -1,
      extended_load: false,
      tab_index: 0,
      tabs: [
        {
          title:'General',
          icon:'house'
        },/*{
          title: 'Status',
          icon:'gear'
        },*/{
          title: 'ABA Files',
          icon:'file-export'
        },{
          title: 'Final Transfer',
          icon: 'check-double'
        },{
          title: 'Transfer Matches',
          icon:'file-import'
        },{
          title: 'Add Transfer',
          icon:'plus'
        },{
          title: 'Flag or Dismiss',
          icon:'flag'
        }
      ],

      //offset: 0,
      limit: this.RESULTS_PER_PAGE,
      total_count: null,
      results_per_page: this.RESULTS_PER_PAGE, // should be the same as limit
      max_num_pages: 5,
      num_pages: null,
      showing_page: 0,

      add_transfer_typed: "",
      add_transfer: null,
      add_transfer_message: null,

      file_code: "",
      result_redressfile: null,
      result_redressfile_message: "",

      confirm_transfer_assignment: false,
      loading: false
    };

    this.on_change_created_at_min = this.on_change_created_at_min.bind(this);
    this.on_change_created_at_max = this.on_change_created_at_max.bind(this);
    this.on_change_transaction_at_min = this.on_change_transaction_at_min.bind(this);
    this.on_change_transaction_at_max = this.on_change_transaction_at_max.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.toggle_selection = this.toggle_selection.bind(this);
    this.select_redress = this.select_redress.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.add_message = this.add_message.bind(this);
    this.set_match_as_primary = this.set_match_as_primary.bind(this);
    this.search_add_transfer = this.search_add_transfer.bind(this);
    this.redressmatch_toggle = this.redressmatch_toggle.bind(this);
    this.search_redressfile = this.search_redressfile.bind(this);
    this.update_redress = this.update_redress.bind(this);
    this.assign_transfer = this.assign_transfer.bind(this);
    this.search = this.search.bind(this);

    this.REDRESS_STATUSES = [1,2,3,4,5,6,7,8,9,10];
    this.REDRESS_STATUS_TO_NAME = {'1':'Submitted','2':'Single Match','3':'No Match','4':'Multiple matches','5':'Approved','6':'Generated','7':'Paid','8':'Flagged','9':'Dismissed','10':'Transfer assigned'};
    this.PURPOSES = [1,2,3];
    this.PURPOSE_TO_TITLE = {
      '1':'Refund',
      '2':'Paid excess',
      '3':'Wrong code'
    };

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    fetch("/api/accounts/index", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        accounts: json.accounts,
        accounts_loaded: true
      });
    });
  }

  on_change_created_at_min(e){
    this.setState({
      created_at_min: (new Date(e.target.value)).toISOString().slice(0,16)
    });
  }

  on_change_created_at_max(e){
    this.setState({
      created_at_max: (new Date(e.target.value)).toISOString().slice(0,16)
    });
  }

  on_change_transaction_at_min(e){
    this.setState({
      transaction_at_min: (new Date(e.target.value)).toISOString().slice(0,16)
    });
  }

  on_change_transaction_at_max(e){
    this.setState({
      transaction_at_max: (new Date(e.target.value)).toISOString().slice(0,16)
    });
  }

  toggle_selection(index){
    if (this.state.selected.includes(index)){
      this.setState({
        selected: this.state.selected.filter((s) => s != index)
      });
    } else {
      this.setState({
        selected: this.state.selected.concat(index).sort()
      });
    }
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  select_redress(index){
    this.setState({
      redress_index: index,
      redress: this.state.results[index],
      show_redress: true,
      extended_load: false
    });
    fetch("/api/redress/"+this.state.results[index].code, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("successfully returned select_redress");
      // rearrange to ensure primary match is on-top?
      this.setState({
        redress: json.redress,
        extended_load: true
      });
    });
  }

  set_match_as_primary(id){
    // /api/redressmatch/:id/admin
    fetch("/api/redressmatch/"+String(id)+"/admin", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({redress:{action: "SET_PRIMARY"}})
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully set as Primary",'success');
        this.select_redress(this.state.redress_index);
      } else {
        this.add_message("Failed to set as Primary",'danger');
      }
    });
  }

  search_add_transfer(){
    fetch("/api/transfers/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({transfer:{internal_code: this.state.add_transfer_typed},offset:0,limit:100,req_count:false,account_code:this.state.accounts[this.state.account_index].internal_code})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Finished add transfer search");
      console.log(json);
      if (json.transfers.length > 0){
        this.setState({
          add_transfer: json.transfers[0],
          add_transfer_message: "Transfer found"
        });
      } else {
        this.setState({
          add_transfer: null,
          add_transfer_message: "Could not find Transfer"
        });
      }
      /*console.log(json);
      this.setState({
        show_results: true,
        results: json.transfers,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page
      });*/
    });
  }

  redressmatch_toggle(){
    // /api/redressmatches/toggle
    fetch("/api/redressmatches/toggle", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({transfer_code: this.state.add_transfer.internal_code, redress_code: this.state.redress.code})
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully "+json.action+" Transfer to Redress",'success');
        this.setState({
          add_transfer: null
        });
        this.select_redress(this.state.redress_index);
      } else {
        this.add_message("Failed to complete action.",'danger');
      }
    });
  }

  search(page,limit,req_count){
    if (!this.state.accounts_loaded){
      return false;
    }
    this.setState({selection: []});
    console.log("page = "+String(page));
    let body = {redress:{},offset:page*this.state.results_per_page,limit:limit,req_count:req_count,account_id:this.state.accounts[this.state.account_index].id}; // need to select account_id ***
    /*
      For each search term in state, only include it in the body if state indicates its inclusion. Separate by whether it has min/max or exact value.
    */
    // min/max keys:
    ['created_at','transaction_at'].forEach((k,i) => this.state['include_'+k] ? body['redress'][k] = {min:this.state[k+'_min'],max:this.state[k+'_max']} : null);
    // exact keys:
    ['code','status','request_type','file_code'].forEach((k,i) => this.state['include_'+k] ? body['redress'][k] = this.state[k] : null);
    /*if (Object.keys(body['invoice']).length === 0){
      body['invoice']['amount'] = {min:100,max:1000000000};
    }*/
    //body['tags'] = this.state.include_tags ? this.state.tags : [];
    console.log('body');
    console.log(body);
    fetch("/api/redresses/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      this.setState({
        show_results: true,
        results: json.redresses,
        total_count: json.count === null ? this.state.total_count : json.count,
        num_pages: Math.ceil((json.count === null ? this.state.total_count : json.count) / this.state.results_per_page),
        showing_page: page,
        search_clicked: true
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  search_redressfile(){
    let body = {redressfile:{company_id:0,code:this.state.file_code},offset:0,limit:1,req_count:false}; // need to select account_id ***
    fetch("/api/redressfiles/search", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if (json.redressfiles.length > 0){
        this.setState({
          result_redressfile: json.redressfiles[0],
          result_redressfile_message: "Result found."
        });
      } else {
        this.setState({
          result_redressfile: null,
          result_redressfile_message: "No result found."
        });
      }
    });
  }

  update_redress(params){
    this.setState({
      loading: true
    });
    fetch("/api/redress/"+this.state.redress.code, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({redress: params})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Server error when processing request",'danger');
      this.setState({
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully updated Redress object.",'success');
        this.setState({
          redress: json.redress,
          loading: false
        });
      } else {
        this.add_message("Failed to update Redress object",'danger');
        this.setState({
          loading: false
        });
      }
    });
  }

  assign_transfer(){
    // /api/transfer/:transfer_code/assign
    fetch("/api/transfer/"+this.state.redress.matches.filter((m) => m.primary_match)[0].transfer.internal_code+"/assign", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({
        redress_code: this.state.redress.code,
        invoice_id: this.state.redress.invoice_id
      })
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Server error when processing request",'danger');
      this.setState({
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.add_message("Successfully assigned Transfer object.",'success');
        this.setState({
          redress: json.redress,
          confirm_transfer_assignment: false,
          loading: false
        });
      } else {
        this.add_message("Failed to assign Transfer object",'danger');
        this.setState({
          confirm_transfer_assignment: false,
          loading: false
        });
      }
    });
  }

  render () {
    console.log("Redress State:");
    console.log(this.state);
    return (
      <div className="form-section" style={{width: '860px'}}>

        {this.state.show_redress ? 
          <Overlay on_cancel={() => this.setState({show_redress: false, tab_index: 0})} title={"Redress "+this.state.redress.code} width="680px">
            <div style={{minHeight: '420px'}}>
              <table style={{width: '100%'}}><tbody>
                <tr style={{verticalAlign: 'top'}}>
                  <td style={{width: '190px'}}>
                  {this.state.tabs.map(
                    (tab, index) =>
                    <div className={this.state.tab_index === index ? "redress-tab-selected" : "redress-tab"} onClick={() => this.setState({tab_index: index})} key={index}>
                      <table><tbody>
                        <tr>
                          <td>
                            <i className={"fa-solid fa-"+tab.icon}></i>
                          </td>
                          <td>
                            {tab.title}
                          </td>
                        </tr>
                      </tbody></table>
                    </div>
                  )}
                  </td>
                  <td style={{verticalAlign: 'top'}}>
                    {this.state.messages.map(
                      (message, index) =>
                      <div className={"flash flash-"+message.type} key={index}>
                        <table><tbody>
                          <tr>
                            <td style={{fontSize: '18px'}}>
                              <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                            </td>
                            <td>
                              {message.content}
                            </td>
                          </tr>
                        </tbody></table>
                      </div>
                    )}
                    {[
                      <div>
                        <table className="redress-view-table"><tbody>
                          <tr>
                            <td>
                              Request Type
                            </td>
                            <td>
                              {this.PURPOSE_TO_TITLE[String(this.state.redress.request_type)]}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Status
                            </td>
                            <td>
                              {this.REDRESS_STATUS_TO_NAME[String(this.state.redress.status)]}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Created at
                            </td>
                            <td>
                              {new Date(this.state.redress.created_at).toLocaleString()}<br />
                              ({Math.floor((new Date() - new Date(this.state.redress.created_at)) / 86400000)} days ago)
                            </td>
                          </tr>
                          {this.state.selected_purpose != 2 ? 
                            <tr>
                              <td>
                                From Bank Account
                              </td>
                              <td>
                                {this.state.redress.account_name}<br />
                                {this.state.redress.bsb.slice(0,3)}-{this.state.redress.bsb.slice(3,6)} {this.state.redress.account_number}
                              </td>
                            </tr> : 
                            null}
                          <tr>
                            <td>
                              Amount
                            </td>
                            <td>
                              AUD {this.amount_to_formatted_string(this.state.redress.amount)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Date of Transaction
                            </td>
                            <td>
                              {(new Date(this.state.redress.transaction_at.slice('T'))).toString().slice(4,15)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Time of Transaction
                            </td>
                            <td>
                              {(new Date(this.state.redress.transaction_at)).toString().slice(15,21)} {(new Date(this.state.redress.transaction_at)).toString().slice(25,34)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Description
                            </td>
                            <td>
                              {this.state.redress.description}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Reference
                            </td>
                            <td>
                              {this.state.redress.reference}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              Receipt Number
                            </td>
                            <td>
                              {this.state.redress.receipt}
                            </td>
                          </tr>
                          {[2,3].includes(this.state.redress.request_type) ? 
                            <tr>
                              <td>
                                Intended Object
                              </td>
                              {[null,undefined].includes(this.state.redress.matched_object_url) ? <td></td> : 
                                <td>
                                  <a href={this.state.redress.matched_object_url} target="_blank"><b>{this.state.redress.matched_object_url.split('/')[this.state.redress.matched_object_url.split('/').length - 2]}</b> <i className="fa-solid fa-arrow-up-right-from-square"></i></a>
                                </td>}
                            </tr> : null}
                        </tbody></table>
                      </div>,

                      <div>
                        {this.state.request_type === 3 ? 
                        null : 
                        <div>
                          <h3>
                            ABA Export File
                          </h3>
                          <h3 style={{color: 'grey'}}>
                            Current Assigned File
                          </h3>
                          {[undefined,null].includes(this.state.redress.redressfile) ? 
                            <div style={{fontSize: '13px', color: 'grey'}}>
                              No ABA file selected.
                            </div> : 
                            <div className="redressmatch-container" style={{cursor: 'default', padding: '8px'}}>
                              <h3>
                                {this.state.redress.redressfile.title}
                              </h3>
                              <table style={{width: '100%'}}><tbody>
                                <tr>
                                  <td>
                                    <div style={{color: 'grey', fontSize: '14px'}}>
                                      {this.state.redress.redressfile.code}<br />
                                      Created {(new Date(this.state.redress.redressfile.created_at)).toLocaleString()}<br />
                                      {this.state.redress.redressfile.num_redresses} redress{this.state.redress.redressfile.num_redresses.length === 1 ? "" : "es"}
                                    </div>
                                  </td>
                                  <td style={{textAlign: 'right'}}>
                                    <button className="button-blue" onClick={() => this.update_redress({redressfile_id: null, file_code: null, file_inclusion_at: null})}>
                                      Clear
                                    </button>
                                  </td>
                                </tr>
                              </tbody></table>
                            </div>}
                          </div>}

                        {this.state.request_type === 3 ? 
                          <div>
                            <p>
                              This Redress requests a reassignment, not a full or partial refund. No ABA file is necessary.
                            </p>
                          </div> : 
                          <div>
                            <br />
                            <h3 style={{color: 'grey'}}>
                              Add ABA File
                            </h3>
                            <h4 style={{textAlign: 'center'}}>
                              <a href="/redressfiles/index" target="_blank" style={{color: 'blue'}}>Search for ABA File Objects (new tab)</a>
                            </h4>
                            <div className="myinput-wrapper-centre">
                              <MyInput onChange={(e) => this.setState({file_code: e.target.value})} onKeyDown={(e) => e.key === 'Enter' ? this.search_redressfile() : null} value={this.state.file_code} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Redressfile code" note={null} />
                            </div>
                            <p style={{color: 'grey', fontSize: '12px'}}>
                              {this.state.result_redressfile_message}
                            </p>
                            {this.state.result_redressfile === null ? null : 
                              <div className="redressmatch-container" style={{cursor: 'default', padding: '8px'}}>
                                <h3>
                                  {this.state.result_redressfile.title}
                                </h3>
                                <table style={{width: '100%'}}><tbody>
                                  <tr>
                                    <td>
                                      <div style={{color: 'grey', fontSize: '14px'}}>
                                        {this.state.result_redressfile.code}<br />
                                        Created {(new Date(this.state.result_redressfile.created_at)).toLocaleString()}<br />
                                        {this.state.result_redressfile.num_redresses} redress{this.state.result_redressfile.num_redresses.length === 1 ? "" : "es"}
                                      </div>
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                      <button className="button-blue" onClick={() => this.update_redress({redressfile_id: this.state.result_redressfile.id, file_code: this.state.result_redressfile.code, file_inclusion_at: (new Date()).toISOString()})}>
                                        Select
                                      </button>
                                    </td>
                                  </tr>
                                </tbody></table>
                              </div>}
                          </div>}
                      </div>,

                      <div>
                        {this.state.redress.status === 10 && this.state.extended_load ? 
                          <div>
                            Redress assigned Transfer object <b>{this.state.redress.matches.filter((m) => m.primary_match)[0].transfer.internal_code}</b> to object <a href={this.state.redress.matched_object_url} target="_blank"><b>{this.state.redress.matched_object_url.split('/')[this.state.redress.matched_object_url.split('/').length - 2]}</b> <i className="fa-solid fa-arrow-up-right-from-square"></i></a>.
                          </div> : null}
                        {this.state.redress.request_type === 3 && this.state.redress.status < 10 && this.state.extended_load ? 
                          <div>
                            <h3>Final Transfer Assignment</h3>
                            {[null,undefined].includes(this.state.redress.matches) ? null
                             : this.state.redress.matches.filter((m) => m.primary_match).map(
                              (match, index) =>
                              <div key={index} className="redressmatch-container" onClick={() => this.setState({match_index: this.state.match_index === index ? -1 : index})}>
                                <table style={{width: '100%'}}><tbody>
                                  <tr>
                                    <td>
                                      <h4 style={{marginTop: '4px', marginBottom: '4px'}}>
                                        {match.transfer.internal_code} <span style={{color:'grey'}}>(${this.amount_to_formatted_string(match.transfer.amount)})</span>
                                      </h4>
                                      <div style={{fontSize: '14px', color: 'grey'}}>
                                        {(new Date(match.created_at)).toLocaleString()}
                                      </div>
                                    </td>
                                    <td style={{width: '85px', verticalAlign: 'top'}}>
                                      <div style={{fontSize: '14px', marginTop: '8px'}}>
                                        Score: <b>{match.score}</b>
                                      </div>
                                      {match.primary_match ? 
                                        <div style={{fontSize: '13px', color: '#32bfb8', marginTop: '4px'}}>
                                          <i className="fa-solid fa-check"></i> {[5,6,7].includes(this.state.redress.status) ? "Confirmed" : "Primary"}
                                        </div> : 
                                        <div style={{fontSize: '13px', color: '#c9c9c9', marginTop: '10px'}}>
                                          -
                                        </div>}
                                    </td>
                                  </tr>
                                </tbody></table>
                                <table style={{color: 'grey', fontSize: '13px'}}><tbody>
                                    <tr>
                                      <td style={{padding: '4px'}}>
                                        Status
                                      </td>
                                      <td style={{padding: '4px'}}>
                                        {match.transfer.bank_status}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{padding: '4px'}}>
                                        Description
                                      </td>
                                      <td style={{padding: '4px'}}>
                                        {match.transfer.description}
                                      </td>
                                    </tr>
                                  </tbody></table>
                                  {this.state.confirm_transfer_assignment ? 
                                    <div>
                                      Are you sure you want to assign this Transfer?<br />
                                      <table><tbody>
                                        <tr>
                                          <td>
                                            <button className="std-button" onClick={this.assign_transfer}>
                                              {this.state.loading ? "Confirming..." : "Confirm"}
                                            </button>
                                          </td>
                                          <td>
                                            <button className="std-button" onClick={() => this.setState({confirm_transfer_assignment: false})}>
                                              Cancel
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody></table>
                                    </div>
                                    : <button className="std-button" onClick={() => this.setState({confirm_transfer_assignment: true})}>
                                      Assign
                                    </button>}
                              </div>
                            )}
                            {this.state.redress.matches.filter((m) => m.primary_match).length === 0 ? 
                              <div>No primary Transfer selected.</div> : null}
                          </div> : 
                          <div>
                            {this.state.redress.status === 10 ? null : <p style={{fontSize: '13px', color: 'grey'}}>
                              This Redress requires a full or partial refund. It does not require an assignment of a Transfer to an Invoice object.
                            </p>}
                          </div>}
                      </div>,

                      <div>
                        <h3>Transfer matches</h3>
                        {this.state.extended_load && this.state.redress.status != 10 ? this.state.redress.matches.map(
                          (match, index) =>
                          <div key={index} className="redressmatch-container" onClick={() => this.setState({match_index: this.state.match_index === index ? -1 : index})}>
                            <table style={{width: '100%'}}><tbody>
                              <tr>
                                <td>
                                  <h4 style={{marginTop: '4px', marginBottom: '4px'}}>
                                    {match.transfer.internal_code} <span style={{color:'grey'}}>(${this.amount_to_formatted_string(match.transfer.amount)})</span>
                                  </h4>
                                  <div style={{fontSize: '14px', color: 'grey'}}>
                                    {(new Date(match.created_at)).toLocaleString()}
                                  </div>
                                </td>
                                <td style={{width: '85px', verticalAlign: 'top'}}>
                                  <div style={{fontSize: '14px', marginTop: '8px'}}>
                                    Score: <b>{match.score}</b>
                                  </div>
                                  {match.primary_match ? 
                                    <div style={{fontSize: '13px', color: '#32bfb8', marginTop: '4px'}}>
                                      <i className="fa-solid fa-check"></i> {[5,6,7].includes(this.state.redress.status) ? "Confirmed" : "Primary"}
                                    </div> : 
                                    <div style={{fontSize: '13px', color: '#c9c9c9', marginTop: '10px'}}>
                                      -
                                    </div>}
                                </td>
                              </tr>
                            </tbody></table>
                            {this.state.match_index === index ? 
                              <div>
                                <table style={{width: '100%'}}><tbody>
                                  <tr>
                                    <td style={{width: '60%'}}>
                                      <table style={{color: 'grey', fontSize: '13px'}}><tbody>
                                        <tr>
                                          <td style={{padding: '4px'}}>
                                            Status
                                          </td>
                                          <td style={{padding: '4px'}}>
                                            {match.transfer.bank_status}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{padding: '4px'}}>
                                            Description
                                          </td>
                                          <td style={{padding: '4px'}}>
                                            {match.transfer.description}
                                          </td>
                                        </tr>
                                      </tbody></table>
                                    </td>
                                    <td style={{textAlign: 'center'}}>
                                      {match.primary_match ? null : <button className="button-blue" onClick={() => this.set_match_as_primary(match.id)}>
                                        Set as Primary
                                      </button>}
                                    </td>
                                  </tr>
                                </tbody></table>
                              </div> : null}
                          </div>
                        ) : (this.state.redress.status === 10 ? <div>Redress finalised with Transfer matched to object.</div> : null)}
                      </div>,
                      <div>
                        {this.state.redress.status > 4 ? <div>Transfer selected and finalised.</div> : 
                        <div>
                        <h3>
                          Add a transfer
                        </h3>
                        <MyInput onChange={(e) => this.setState({add_transfer_typed: e.target.value})} onKeyDown={(e) => e.key === 'Enter' ? this.search_add_transfer() : null} value={this.state.add_transfer_typed} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Transfer code" note={null} />
                        <button className="std-button" onClick={this.search_add_transfer}>
                          Search
                        </button>
                        <div style={{fontSize: '14px', marginTop: '8px', marginBottom: '8px'}}>
                          {this.state.add_transfer_message}
                        </div>
                        {this.state.add_transfer === null ? null : 
                          <div className="redressmatch-container">
                            <table style={{width: '100%'}}><tbody>
                              <tr>
                                <td>
                                  <h4 style={{marginTop: '4px', marginBottom: '4px'}}>
                                    {this.state.add_transfer.internal_code}
                                  </h4>
                                  <div style={{fontSize: '14px', color: 'grey'}}>
                                    {(new Date(this.state.add_transfer.created_at)).toLocaleString()}
                                  </div>
                                </td>
                                <td style={{width: '85px', verticalAlign: 'top'}}>
                                  
                                </td>
                              </tr>
                            </tbody></table>
                            <div>
                              <table style={{width: '100%'}}><tbody>
                                <tr>
                                  <td style={{width: '60%'}}>
                                    <table style={{color: 'grey', fontSize: '13px'}}><tbody>
                                      <tr>
                                        <td style={{padding: '4px'}}>
                                          Status
                                        </td>
                                        <td style={{padding: '4px'}}>
                                          {this.state.add_transfer.bank_status}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{padding: '4px'}}>
                                          Description
                                        </td>
                                        <td style={{padding: '4px'}}>
                                          {this.state.add_transfer.description}
                                        </td>
                                      </tr>
                                    </tbody></table>
                                  </td>
                                  <td style={{textAlign: 'center'}}>
                                    {this.state.redress != undefined && this.state.redress.matches != undefined && this.state.redress.matches.filter((m,i) => m.transfer.internal_code === this.state.add_transfer.internal_code).length > 0 ? 
                                      <button className="button-blue" onClick={this.redressmatch_toggle}>
                                        Remove
                                      </button> : 
                                      <button className="button-blue" onClick={this.redressmatch_toggle}>
                                        Add
                                      </button>}
                                  </td>
                                </tr>
                              </tbody></table>
                          </div>
                        </div>}
                        </div>}
                      </div>,
                      
                      <div>
                        <h3>
                          Flag or Dismiss
                        </h3>
                        <table style={{margin: 'auto'}}><tbody>
                          <tr>
                            <td style={{padding: '5px'}}>
                              Current status
                            </td>
                            <td style={{padding: '5px'}}>
                              <b>{this.REDRESS_STATUS_TO_NAME[this.state.redress.status]}</b>
                            </td>
                          </tr>
                        </tbody></table>
                        {this.state.show_dismiss_redress ? 
                          <div>
                            Are you sure you want to dismiss this Redress?
                          </div> : 
                          <div>
                            <p>
                              Flag or dismiss Redress.
                            </p>
                            <table><tbody>
                              <tr>
                                <td>
                                  <button className="std-button" onClick={() => this.state.redress.status === 8 ? this.update_redress({status: [1,2,4][Math.min(this.state.redress.matches.length, 2)]}) : this.update_redress({status: 8})}>
                                    <i className="fa-regular fa-flag"></i> {this.state.loading ? "Loading..." : null}{this.state.loading ? null : (this.state.redress.status === 8 ? "Flagged" : "Flag")}
                                  </button>
                                </td>
                                <td>
                                  <button className="std-button" onClick={() => this.state.redress.status === 9 ? this.update_redress({status: [1,2,4][Math.min(this.state.redress.matches.length, 2)]}) : this.update_redress({status: 9})}>
                                    <i className="fa-solid fa-square-xmark"></i> {this.state.loading ? "Loading..." : null}{this.state.loading ? null : (this.state.redress.status === 9 ? "Dismissed" : "Dismiss")}
                                  </button>
                                </td>
                              </tr>
                            </tbody></table>
                          </div>}
                      </div>
                    ][this.state.tab_index]}
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}


        {this.state.messages.map(
            (message, index) =>
            <div className={"flash flash-"+message.type} key={index}>
              <table><tbody>
                <tr>
                  <td style={{fontSize: '18px'}}>
                    <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                  </td>
                  <td>
                    {message.content}
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
        <h2>
          Redress Console
        </h2>
        <div>
          <h3 style={{color: 'grey'}}>
            Select an Account
          </h3>
          <select className="general-select" onChange={(e) => this.setState({account_index: Number(e.target.value)})}>
            {this.state.accounts.map(
              (account, index) =>
              <option key={index} value={index}>
                {account.bsb} {account.account_number} - {account.account_name} ({account.fi_abbreviation})
              </option>
            )}
          </select>
        </div>
        <h3 style={{color: 'grey'}}>
          Selection Criteria
        </h3>
        <table id="object-search-table"><tbody>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_status: v})} init_value={this.state.include_status} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Status
              </div>
              {this.state.include_status ? 
                <div>
                  <table><tbody>
                    {this.REDRESS_STATUSES.map(
                      (s, index) => 
                        <tr key={index}>
                          <td style={{width: '24px'}}>
                            {/*<button onClick={() => this.setState({status: this.state.status.includes(s) ? this.state.status.filter((e) => e != s) : this.state.status.concat([s])})}>
                              {this.state.status.includes(s) ? "Included" : "Excluded"}
                            </button>*/}
                            <Checkbox on_toggle={(v) => this.setState({status: v ? this.state.status.concat([s]) : this.state.status.filter((e) => e != s)})} />
                          </td>
                          <td>
                            {this.REDRESS_STATUS_TO_NAME[String(s)]}
                          </td>
                        </tr>
                    )}
                  </tbody></table>
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_created_at: v})} init_value={this.state.include_created_at} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Date created at
              </div>
              {this.state.include_created_at ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput type="datetime-local" onChange={this.on_change_created_at_min} value={this.state.created_at_min} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Earliest date" note={null} /><br />
                  <MyInput type="datetime-local" onChange={this.on_change_created_at_max} value={this.state.created_at_max} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Latest date" note={null} /><br />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_transaction_at: v})} init_value={this.state.include_transaction_at} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Transaction at
              </div>
              {this.state.include_transaction_at ? 
                <div style={{paddingTop: '15px'}}>
                  <MyInput type="datetime-local" onChange={this.on_change_transaction_at_min} value={this.state.transaction_at_min} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Earliest date" note={null} /><br />
                  <MyInput type="datetime-local" onChange={this.on_change_transaction_at_max} value={this.state.transaction_at_max} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="Latest date" note={null} /><br />
                </div> : null}
            </td>
          </tr>
          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_request_type: v})} init_value={this.state.include_request_type} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Request Types
              </div>
              {this.state.include_request_type ? 
                <div>
                  <table><tbody>
                    {this.PURPOSES.map(
                      (s, index) => 
                        <tr key={index}>
                          <td style={{width: '24px'}}>
                            {/*<button onClick={() => this.setState({status: this.state.status.includes(s) ? this.state.status.filter((e) => e != s) : this.state.status.concat([s])})}>
                              {this.state.status.includes(s) ? "Included" : "Excluded"}
                            </button>*/}
                            <Checkbox on_toggle={(v) => this.setState({request_type: v ? this.state.request_type.concat([s]) : this.state.request_type.filter((e) => e != s)})} />
                          </td>
                          <td>
                            {this.PURPOSE_TO_TITLE[String(s)]}
                          </td>
                        </tr>
                    )}
                  </tbody></table>
                </div> : null}
            </td>
          </tr>

          <tr>
            <td>
              <Checkbox on_toggle={(v) => this.setState({include_file_code: v})} init_value={this.state.include_file_code} />
            </td>
            <td>
              <div style={{fontSize: '16px', fontWeight: 'bold'}}>
                Redress File
              </div>
              {this.state.include_file_code ? 
                <div>
                  <br />
                  <MyInput onChange={(e) => this.setState({file_code: e.target.value})} value={this.state.file_code} onBlur={() => console.log('a')/*this.on_blur_date_accrued*/} status={0} label="ABA file code" note={null} /><br />
                  {/*<button className="button-blue">
                    Apply
                  </button>*/}
                </div> : null}
            </td>
          </tr>
        </tbody></table>

        {this.state.search_clicked ? 
          <div>
            <h3 style={{color: 'grey'}}>
              Results
            </h3>
            <table className="redress-result-table"><tbody>
              {this.state.results.length > 0 ? <tr>
                <th>
                  <Checkbox on_toggle={(v) => this.setState({selected: this.state.selected.length === this.state.results.length && this.state.results.length > 0 ? [] : Array.from({ length: this.state.results.length }, (_, i) => i)})} init_value={this.state.selected.length === this.state.results.length} />
                </th>
                <th>
                  Code
                </th>
                <th>
                  Request type
                </th>
                <th>
                  Status
                </th>
                <th>
                  Created at ({String(new Date()).slice(25,33)})
                </th>
                <th>
                  Match score
                </th>
              </tr> : null}
              {this.state.results.length > 0 ? this.state.results.map(
                (result, index) => 
                <tr key={index}>
                  <td>
                    <Checkbox on_toggle={(v) => this.toggle_selection(index)} init_value={this.state.selected.includes(index)} />
                  </td>
                  <td>
                    <div style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.select_redress(index)}>{result.code}</div>
                  </td>
                  <td>
                    {this.PURPOSE_TO_TITLE[String(result.request_type)]}
                  </td>
                  <td>
                    {this.REDRESS_STATUS_TO_NAME[String(result.status)]}
                  </td>
                  <td>
                    {(new Date(result.created_at)).toLocaleString()}
                  </td>
                  <td>
                    {result.score === null ? (result.num_matches === 0 ? "No matches" : "-") : result.score}
                  </td>
                </tr>
              ) : null}
            </tbody></table>
            {this.state.results.length === 0 ? <div style={{color: 'grey', fontSize: '14px'}}>
              No results.
            </div> : null}
          </div> : null}

        <button className="std-button" onClick={() => this.search(0,this.state.limit,true)}>
          Search
        </button>

        {this.state.total_count != null ? 
          <table className="search-pagination-table"><tbody>
            <tr>
              <td>
                {this.state.showing_page > this.state.max_num_pages / 2 ? 
                  <div onClick={() => this.search(this.state.showing_page - ((this.state.max_num_pages-1)/2) - 1, this.state.limit, false)}>
                    ...
                  </div> : null}
              </td>
              {this.state.num_pages <= this.state.max_num_pages || this.state.showing_page < this.state.max_num_pages / 2 ? 
                Array(Math.min(this.state.num_pages,this.state.max_num_pages)).fill(0).map((_,index) => 
                  <td key={index}>
                    <div onClick={() => this.search(index,this.state.limit,false)} style={this.state.showing_page === index ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                      {index+1}
                    </div>
                  </td>) : 
                Array(this.state.max_num_pages).fill(0).map((_,i) => this.state.showing_page - ((this.state.max_num_pages - 1)/2) + i).map((v,index) => 
                  this.state.num_pages - v < ((this.state.max_num_pages - 1) / 2) - 1 ? null : 
                    <td key={index}>
                      <div onClick={() => this.search(v,this.state.limit,false)} style={this.state.showing_page === v ? {backgroundColor: '#040dba', color: '#fff'} : {backgroundColor: '#c9c9c9', color: 'black'}}>
                        {v + 1}
                      </div>
                    </td>)}
              <td>
                {this.state.num_pages - this.state.showing_page > this.state.max_num_pages / 2 + 1 ? 
                  <div onClick={() => this.search(Math.max(this.state.showing_page + ((this.state.max_num_pages - 1) / 2) + 1,this.state.max_num_pages),this.state.limit,false)}>
                    ...
                  </div> : null}
              </td>
            </tr>
          </tbody></table> : null}
      </div>
    );
  }
}

export default RedressIndex
