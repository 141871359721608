import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"
import MyInput from "./MyInput.js"

class PayorAccountDelete extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      show_delete: false,
      loading: false,
      typed_password: "",
      messages: []
		};
    
    this.confirm_delete = this.confirm_delete.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  confirm_delete(){
    this.setState({
      loading: true
    });
    // /api/payor
    fetch("/api/payor", {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({password: this.state.typed_password})
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      this.setState({
        loading: false
      });
      this.add_message("Failed to delete.",'danger');
    }).then(json => {
      this.setState({
        loading: false
      });
      if (!json.success){
        this.add_message("Failed to delete. Error: "+json.error,'danger');
      } else {
        this.add_message("Successfully updated.",'success');
        location.href = "/";
      }
      //this.props.on_successful_update('info');
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }
  
  render () {
    return (
      <div>
        {this.state.show_delete ? 
          <Overlay on_cancel={() => this.setState({show_delete: false})} title="Confirm Delete">
            <div>
              {this.state.messages.map(
                (message, index) =>
                <div className={"flash flash-"+message.type} key={index}>
                  <table><tbody>
                    <tr>
                      <td style={{fontSize: '18px'}}>
                        <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                      </td>
                      <td>
                        {message.content}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}
            
              <p>
                You need to type your password to confirm these changes.
              </p>
              <MyInput onKeyDown={null/*(e) => e.code === 'Enter' ? this.confirm_delete() : null*/} onChange={(e) => this.setState({typed_password: e.target.value})} value={this.state.typed_password} onFocus={null/*() => this.setState({password_confirmation_status: 1})*/} onBlur={null/*() => console.log("Password confirmation blurred")*/} status={1/*this.state.password_confirmation_status*/} note={null} label="Password" type="password" />
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" style={{backgroundColor: 'red'}} onClick={this.confirm_delete}>
                      {this.state.loading ? "Deleting" : "Delete"}
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_delete: false})}>
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}
        
        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <h3>
          Payor Account Deletion
        </h3>
        <p className="general-p">
          You can permanently your Payor account.
        </p>
        <p className="general-p">
          <span style={{color: 'red'}}>You cannot undo this action.</span>
        </p>
        <button className="std-button" style={{backgroundColor: 'red'}} onClick={() => this.setState({show_delete: true})}>
          Delete
        </button>
      </div>
    );
  }
}

export default PayorAccountDelete
