import React from "react"
import PropTypes from "prop-types"
import CustomerPayment from "./CustomerPayment.js"

class ShowCustomer extends React.Component {
  constructor(props){
		super(props);
    this.TRANSFERS_PER_PAGE = 5;
    this.state = {
      customer: null,
      init_loaded: false,
      messages: [],
      loading: false
		};

    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.toggle_url_passkey_inclusion = this.toggle_url_passkey_inclusion.bind(this);
    this.display_abn = this.display_abn.bind(this);
    this.click_to_copy = this.click_to_copy.bind(this);
    this.load_transfers = this.load_transfers.bind(this);

  }

  componentDidMount(){
    fetch("/api/customer/"+this.props.code+(this.props.passkey === null || this.props.passkey === undefined ? "" : "?pk="+this.props.passkey), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      if ([null,undefined].includes(json.customer)){
        this.add_message("Encountered error obtaining information.",'danger');
        this.setState({
          loaded: true
        });
      } else {
        //this.add_message("Received Customer object.",'success');
        this.setState({
          customer: json.customer,
          init_loaded: true
        });
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  toggle_url_passkey_inclusion(){
    let customer = this.state.customer;
    if (customer.short_url.includes('?pk')){
      customer.short_url = customer.short_url.split('?pk')[0];
    } else {
      customer.short_url += ('?pk='+this.props.passkey);
    }
    this.setState({
      customer: customer
    });
  }

  display_abn(x){
    return x.split('').map((a,i) => [1,4,7].includes(i) ? a+' ' : a).join('');
  }

  click_to_copy(e, text){
    navigator.clipboard.writeText(text);
    console.log(e);
    e.target.innerHTML = "COPIED!"
    setTimeout(() => {
      e.target.innerHTML = "COPY";
    }, 1000);
  }

  load_transfers(offset, limit){
    this.setState({
      loading: true
    });
    fetch("/customer/"+this.props.code+"/transfers?limit="+String(limit)+"&offset="+offset, {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      console.log(json);
      let transfers = this.state.customer.transfers.concat(json.transfers);
      let customer = this.state.customer;
      customer.transfers = transfers;
      this.setState({
        customer: customer,
        loading: false
      });
    });
  }
  
  render () {
    console.log("Customer state:");
    console.log(this.state);
    return (this.state.init_loaded ? 
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td>

                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <div style={{backgroundColor: this.state.customer.status >= 0 ? '#32bfb8' : '#e6a330', color: '#fff', padding: '3px', fontSize: '13px', borderRadius: '4px', width: '80px', textAlign: 'center', fontWeight: 'bold', cursor: 'pointer'}}>
                {this.state.customer.status >= 0 ? 'ACTIVE' : 'SUSPENDED'}
              </div>
            </td>
            <td>
              <div style={{fontSize: '16px', color: 'grey', textAlign: 'right', margin: '5px', fontFamily: 'Roboto Mono'}}>
                Cus. # {this.state.customer.code}
              </div>
            </td>
          </tr>
        </tbody></table>

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
              <h2>
                {this.state.customer.name}
              </h2>
            </td>
            {this.state.customer.balance_visible ? 
              <td style={{textAlign: 'right'}}>
                <h2 style={{color: 'grey'}}>
                  ${this.amount_to_formatted_string(this.state.customer.balance)}
                </h2>
              </td> : null}
          </tr>
        </tbody></table>

        <table style={{width: '100%'}}><tbody>
          <tr>
            <td style={{width: '75%', verticalAlign: 'top'}}>
          <table className="invoice-table"><tbody>
            <tr>
              <td>
                Customer email
              </td>
              <td>
                {this.state.customer.email}
              </td>
            </tr>
            <tr>
              <td>
                Organisation
              </td>
              <td>
                {this.state.customer.company_name}
              </td>
            </tr>
            {this.state.customer.company_abn === null ? null : 
            <tr>
              <td>
                ABN
              </td>
              <td>
                {this.display_abn(this.state.customer.company_abn)}
              </td>
            </tr>}
            <tr>
              <td>
                Website
              </td>
              <td>
                <a href={this.state.customer.company_website} style={{color: "grey", textDecoration: "underline"}} target="_blank">{this.state.customer.company_website}</a>
              </td>
            </tr>
            {this.state.customer.balance_visible ? <tr>
              <td>
                Balance
              </td>
              <td>
                <b>${this.amount_to_formatted_string(this.state.customer.balance)}</b>
              </td>
            </tr> : null}
            <tr>
              <td>
                Short URL
              </td>
              <td>
                <span style={{fontFamily: 'Roboto Mono'}}>invc.me/{this.state.customer.short_url}</span>
                <br /><span onClick={(e) => this.click_to_copy(e, 'invc.me/'+this.state.customer.short_url)} className="copy-span">COPY</span>
                {this.props.passkey === null ? null : <span onClick={this.toggle_url_passkey_inclusion} className="copy-span">W/{this.state.customer.short_url.includes('?pk') ? 'O' : null} PASSKEY</span>}
              </td>
            </tr>
          </tbody></table>
            </td>
            <td>
              <div dangerouslySetInnerHTML={{__html: this.state.customer.short_url.includes('?pk') ? this.props.qrcode_pk : this.props.qrcode}}>

              </div>
            </td>
          </tr>
        </tbody></table>

        {this.state.customer.balance_visible ? <div className="invoice-tally" style={{marginTop: '40px'}}>
          <table><tbody>
            <tr>
              <td>
                Received
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.customer.total_received)}
              </td>
            </tr>
            <tr>
              <td>
                External credit
              </td>
              <td>
                {this.amount_to_formatted_string(this.state.customer.total_added_externally)}
              </td>
            </tr>
            <tr>
              <td>
                Debit
              </td>
              <td>
                -{this.amount_to_formatted_string(this.state.customer.total_debited)}
              </td>
            </tr>
            <tr>
              <td>
                Balance
              </td>
              <td style={{borderTop: 'solid', borderTopWidth: '1px', borderColor: '#c9c9c9'}}>
                {this.amount_to_formatted_string(this.state.customer.balance)}
              </td>
            </tr>
          </tbody></table>
        </div> : null}

        {this.state.customer.payments_are_visible ? 
          <div>
            <h3>
              Recognised payments
            </h3>
            {this.state.customer.transfers.length === 0 ? 
              <p style={{color: 'grey', fontSize: '13px'}}>
                There are no transfers recognised on this Customer object.
              </p> : 
              <div>
                <table className="customer-transfer-table"><tbody>
                  <tr>
                    <th>
                      Code
                    </th>
                    <th>
                      Transferred at
                    </th>
                    <th>
                      Amount
                    </th>
                  </tr>
                  {this.state.customer.transfers.map(
                    (transfer, index) => 
                      <tr key={index}>
                        <td>
                          {transfer.internal_code}
                        </td>
                        <td>
                          {String(new Date(transfer.transferred_at * 1000)).slice(4,33)}
                        </td>
                        <td style={{textAlign: 'right'}}>
                          ${this.amount_to_formatted_string(transfer.amount)}
                        </td>
                      </tr>
                  )}
                </tbody></table>
              </div>}
            {this.state.customer.transfers.length < this.state.customer.transfer_count ? 
              <div style={{textAlign: 'center'}}>
                <a onClick={() => this.load_transfers(this.state.customer.transfers.length, this.TRANSFERS_PER_PAGE)} style={{fontFamily: 'Roboto Mono', color: 'black', fontSize: '14px', textDecoration: 'underline', cursor: 'pointer', color: 'grey'}}>
                  {this.state.loading ? "Loading" : "Load"} more
                </a>
              </div> : null}
          </div> : null}

        {this.state.customer.status < 0 ? 
          <div style={{marginTop: '40px', textAlign: 'center'}}>
            <h3>
              This customer cannot be credited.
            </h3>
          </div> : 
          <div style={{marginTop: '40px'}}>
            <h3>
              Credit this Customer's account
            </h3>
            <CustomerPayment code={this.props.code} passkey={this.props.passkey} auth_token={this.props.auth_token} />
          </div>}

      </div>
    : <div>
      Loading...
    </div>);
  }
}

export default ShowCustomer
