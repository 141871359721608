import React from "react"
import PropTypes from "prop-types"
import Overlay from "./Overlay.js"

class Consent extends React.Component {
  constructor(props){
		super(props);
    this.state = {
      consents: [],
      loading: true,
      messages: [],
      show_consent: false,
      selected_index: -1,
      show_accounts: false,
      show_delete: false,
      show_new_consent: false
		};

    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.add_consent = this.add_consent.bind(this);
    this.execute_consent = this.execute_consent.bind(this);
    this.delete_consent = this.delete_consent.bind(this);
    this.sync_consents = this.sync_consents.bind(this);

    this.CONSENT_STATUS_TO_NAME = {'1':'PENDING','2':'CONFIRMED','3':'EXPIRED'};
    this.CONSENT_STATUS_TO_COLOUR = {'1':'#040dba','2':'#32bfb8','3':'#e6a330'};

    this.MESSAGE_TYPE_TO_ICON = {'success':'check','info':'circle-info','warning':'circle-exclamation','danger':'circle-exclamation'};

  }

  componentDidMount(){
    fetch("/api/consents", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to obtain Consent list.",'danger');
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        consents: json.consents,
        loading: false
      });
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  add_consent(){
    if (this.state.consents.map((c) => [1,2,4].includes(c.status)).includes(true)){
      this.add_message("You already have a non-expired Consent created.",'danger');
      return true;
    }
    let index = this.state.consents.map((c) => c.status === 1).indexOf(true);
    if (index >= 0){
      this.setState({
        selected_index: index,
        show_consent: true
      });
      return true;
    } else {
      this.setState({
        loading: true
      });
    }
    // /api/consents/create
    fetch("/api/consents/create", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to create Consent on PaySolve server.",'danger');
      this.setState({
        show_consent: false
      })
      throw new Error('Request fail');
    }).then(json => {
      console.log("Result");
      console.log(json);
      if (json.success){
        this.add_message("Successfully created Consent on PaySolve server. Awaiting consent confirmation by you.",'success');
        this.setState({
          consents: json.consents,
          selected_index: -1, //json.consents.map((c) => c.code === json.consent.code).indexOf(true),
          show_consent: false,
          loading: false,
          show_new_consent: false
        });
      } else {
        this.add_message("Could not create Consent on PaySolve server.",'danger');
        this.setState({
          loading: false
        });
      }
    });
  }

  delete_consent(){
    this.setState({
      loading: true
    });
    // /api/consent/:code
    fetch("/api/consent/"+this.state.consents[this.state.selected_index].code, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Error encountered: "+json.error,'danger');
      this.setState({
        show_consent: false,
        show_delete: false,
        loading: false
      });
      throw new Error('Request fail');
    }).then(json => {
      if (json.success){
        this.setState({
          show_consent: false,
          show_delete: false,
          selected_index: -1,
          loading: false,
          consents: json.consents
        });
        this.add_message("Successfully deleted Consent object in Basiq and PaySolve.",'success');
      } else {
        this.add_message("Failed to delete Consent. Error: "+json.error,'danger');
        this.setState({
          show_consent: false,
          show_delete: false,
          loading: false
        });
      }
    });
  }

  execute_consent(){
    this.setState({
      loading: true
    });
    // /api/consents/execute
    fetch("/api/consents/execute", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({code: this.state.consents[this.state.selected_index].code})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to execute Consent on PaySolve server.",'danger');
      this.setState({
        show_consent: false,
        loading: false
      });
      return response.json(); // delet
      throw new Error('Request fail');
    }).then(json => {
      console.log("Returned JSON (execute consent):");
      console.log(json);
      if (json.success){
        this.add_message("Successfully executed Consent on PaySolve server. Awaiting consent confirmation by you.",'success');
        window.open(json.url,'_blank');
        this.setState({
          //consents: json.consents,
          //selected_index: json.consents.map((c) => c.code === json.consent.code).indexOf(true),
          //selected_index: 0,
          //show_consent: false,
          loading: false
        });
        //window.location.href = json.url;
        /*
        window.open(
          json.url,
          '_blank'
        );
        */
      } else {
        this.add_message("Could not execute Consent. Server message: "+json.error,'danger');
      }
    });
  }

  sync_consents(){
    fetch("/api/consents/syncaccounts", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify({code: this.state.consents[this.state.selected_index].code})
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.add_message("Failed to execute Consent on PaySolve server.",'danger');
      this.setState({
        show_consent: false,
        loading: false
      });
      return response.json(); // delet
      throw new Error('Request fail');
    }).then(json => {
      console.log("Returned Sync Consent");
      console.log(json);
      if (json.success){
        let consents = this.state.consents;
        consents[this.state.selected_index] = json.consent;
        this.setState({
          consents: consents
        });
        this.add_message("Successfully synced Consent.", 'success');
      } else {
        this.add_message("Failed to sync Consent.",'danger');
      }
    });
  }

  render () {
    console.log(this.state);
    let messages = this.state.messages.map(
      (message, index) =>
      <div className={"flash flash-"+message.type} key={index}>
        <table><tbody>
          <tr>
            <td style={{fontSize: '18px'}}>
              <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
            </td>
            <td>
              {message.content}
            </td>
          </tr>
        </tbody></table>
      </div>
    );
    let selected_consent = this.state.consents[this.state.selected_index];
    return (
      <div className="form-section">

        {this.state.show_consent ? 
          <Overlay on_cancel={() => this.setState({show_consent: false, show_delete: false})} title={"Consent created at "+(new Date(selected_consent.created_at)).toString().slice(4,24)} width="540px">
            {this.state.show_delete ? 
              <div>
                <p style={{fontSize: '15px', textAlign: 'center'}}>
                  Are you sure you want to delete this Consent?
                </p>
                <table><tbody>
                  <tr>
                    <td>
                      <button className="std-button" style={{backgroundColor: 'red'}} onClick={this.delete_consent}>
                        {this.state.loading ? "Deleting..." : "Delete"}
                      </button>
                    </td>
                    <td>
                      <button className="std-button" onClick={() => this.setState({show_delete: false})}>
                        Cancel
                      </button>
                    </td>
                  </tr>
                </tbody></table>
              </div> : 
              <div>
                {messages}

                {this.state.consents.map((c,i) => c.status > 1).includes(true) && selected_consent.status === 1 ? 
                  <div className="flash flash-warning">
                    <table><tbody>
                      <tr>
                        <td style={{fontSize: '18px'}}>
                          <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['warning']}></i>
                        </td>
                        <td>
                          You cannot confirm this Consent as there is another confirmed Consent. Please delete that one before you confirm this one.
                        </td>
                      </tr>
                    </tbody></table>
                  </div> : null}

                <table><tbody>
                  <tr>
                    <td>
                      <div style={{backgroundColor: this.CONSENT_STATUS_TO_COLOUR[String(selected_consent.status)]}} className="status-box">
                        {this.CONSENT_STATUS_TO_NAME[String(selected_consent.status)]}
                      </div>
                    </td>
                    <td style={{paddingLeft:'5px'}}>
                      <h3>
                        Consent <span style={{fontFamily: 'Roboto Mono'}}>{selected_consent.code}</span>
                      </h3>
                    </td>
                  </tr>
                </tbody></table>
                <table className="account-user-table" style={{width: "90%"}}><tbody>
                  {[['identifier','Basiq ID'],['created_at','Created at'],['expires_at','Expiry date'],['user_identifier','Basiq user ID']].map(
                    (line, index) =>
                    <tr key={index}>
                      <td>
                        {line[1]}
                      </td>
                      <td>
                        {[null,undefined].includes(selected_consent[line[0]]) ? "Not set" : null}{line[0].includes('at') && ![null,undefined].includes(selected_consent[line[0]]) ? (new Date(selected_consent[line[0]])).toString().slice(4,15) : selected_consent[line[0]]}
                      </td>
                    </tr>
                  )}
                </tbody></table>
                <div style={{cursor: 'pointer', marginLeft: '40px'}} onClick={() => this.setState({show_accounts: !this.state.show_accounts})}>
                  <table style={{fontSize: '14px'}}><tbody>
                    <tr>
                      <td style={{width: '30px', textAlign: 'cente'}}>
                        {this.state.show_accounts ?  <i className="fa-solid fa-caret-down"></i> : <i className="fa-solid fa-caret-right"></i>}
                      </td>
                      <td>
                        {this.state.show_accounts ? "Hide accounts" : "Show accounts"} ({selected_consent.accounts.length})
                      </td>
                    </tr>
                  </tbody></table>
                </div>
                {this.state.show_accounts ? 
                  <table style={{width: '400px', fontFamily: 'Roboto Mono', fontSize: '13px', marginLeft: '40px'}}><tbody>
                    <tr style={{color: 'grey'}}>
                      <td style={{margin: '5px'}}>
                        BSB
                      </td>
                      <td style={{margin: '5px'}}>
                        Acct No.
                      </td>
                      <td style={{margin: '5px'}}>
                        Acct name
                      </td>
                    </tr>
                    {selected_consent.accounts.map(
                      (account, index) =>
                      <tr key={index}>
                        <td style={{margin: '5px'}}>
                          {account.bsb}
                        </td>
                        <td style={{margin: '5px'}}>
                          {account.account_number}
                        </td>
                        <td style={{margin: '5px'}}>
                          {account.account_name}
                        </td>
                      </tr>)}
                  </tbody></table>
                  : null}
                {selected_consent.status === 1 && !this.state.consents.map((c,i) => c.status > 1).includes(true) ? 
                  <div style={{textAlign: 'center'}}>
                    <p style={{fontSize: '15px'}}>
                      Confirm Consent via our CDR partner Basiq.
                    </p>
                    <button onClick={this.execute_consent} className="std-button">
                      {this.state.loading ? "Confirming..." : "Confirm"}
                    </button>
                  </div> : null}
                <button className="std-button" style={{backgroundColor: 'red', marginTop: '20px'}} onClick={() => this.setState({show_delete: true})}>
                  Delete
                </button>
                <button className="std-button" style={{marginTop: '20px'}} onClick={() => this.sync_consents()}>
                  Sync Accounts
                </button>
              </div>}
          </Overlay> : null}

        {this.state.show_new_consent ? 
          <Overlay on_cancel={() => this.setState({show_new_consent: false})} title="New Consent" width="540px">
            <div>
              {this.state.consents.length > 0 ? 
                <div className="flash flash-warning">
                  <table><tbody>
                    <tr>
                      <td style={{fontSize: '18px'}}>
                        <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['warning']}></i>
                      </td>
                      <td>
                        You will be superseding your previous Consent {this.state.consents.filter((c) => c.status === 2)[0].code} created at {this.state.consents.filter((c) => c.status === 2)[0].created_at.slice(0,10)}.
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null}
              <div style={{fontSize: '15px', padding: '20px'}}>
                Confirm that you wish to create a new Consent object. This will allow us to get the relevant data from your Australian bank account so that you can create Invoice, Customer and Fund objects.
              </div>
              <table><tbody>
                <tr>
                  <td>
                    <button className="std-button" onClick={this.add_consent}>
                      Create
                    </button>
                  </td>
                  <td>
                    <button className="std-button" onClick={() => this.setState({show_new_consent: false})}>
                      Cancel
                    </button>
                  </td>
                </tr>
              </tbody></table>
            </div>
          </Overlay> : null}

        {messages}

        <h2>
          Bank Account Consents
        </h2>
        <div style={{display: 'table', margin: 'auto'}}>
          {this.state.consents.map(
            (consent, index) => 
            <div key={index} className="account-user-card" style={{width: '500px'}} onClick={() => this.setState({show_consent: true, selected_index: index})}>
              <div style={{display: 'inline-block', width: '100px'}}>
                <div style={{backgroundColor: this.CONSENT_STATUS_TO_COLOUR[String(consent.status)]}} className="status-box">
                  {this.CONSENT_STATUS_TO_NAME[String(consent.status)]}
                </div>
              </div><div style={{display: 'inline-block'}}>
                <div style={{fontSize: '18px', fontWeight: 'bold'}}>
                  Created at {(new Date(consent.created_at)).toString().slice(4,24)}
                </div>
              </div>
              <table style={{width: '100%'}}><tbody>
                <tr>
                  <td>
                    PaySolve code
                  </td>
                  <td style={{minWidth: '300px'}}>
                    {consent.code}
                  </td>
                </tr>
                <tr>
                  <td>
                    Basiq code
                  </td>
                  <td>
                    {/*this.CONSENT_STATUS_TO_NAME[consent.status]*/}
                    {consent.identifier}
                  </td>
                </tr>
                <tr>
                  <td>
                    Expires at
                  </td>
                  <td>
                    {[null,undefined].includes(consent.expires_at) ? "Not yet set" : (new Date(consent.expires_at)).toString().slice(4,24)}
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
          {this.props.admin ? 
            <div className="account-user-card" style={{textAlign: 'center', fontSize: '13px', color: 'grey', width: '500px'}} onClick={() => this.setState({show_new_consent: true})}>
              <i className="fa-solid fa-plus"></i> Add Consent
            </div> : null}
        </div>
      </div>
    );
  }
}

export default Consent
