import React from "react"
import PropTypes from "prop-types"
import MyInput from "./MyInput.js"
import Toggle from "./Toggle.js"
import Overlay from "./Overlay.js"
import Checkbox from "./Checkbox.js"

class NewInvoice extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      typed_title: "New Invoice",
      typed_title_status: 0,
      typed_title_error: null,
      typed_amount: "$1.00",
      displayed_amount: "$1.00",
      amount: 100,
      typed_amount_status: 0,
      typed_amount_error: null,
      typed_description: "",
      billing_name: "",
      billing_name_status: 0,
      billing_name_error: "",
      include_email: true,
      billing_email: "",
      billing_email_status: 0,
      billing_email_error: "",
      date_accrued_at: (new Date()).toISOString().slice(0,10),
      date_accrued_status: 0,
      date_accrued_error: "",
      has_due_date: false,
      due_at: [(new Date(Date.now() + 7 * 86400000)).toISOString().split('T')[0],"23:59:59"].join('T'), //(new Date(Date.now() + 7 * 86400000)).toISOString(),
      has_late_fees: false,
      late_fee_rate: 100,
      typed_late_fee_rate: "$1.00",
      displayed_late_fee_rate: "$1.00",
      late_fee_rate_status: 1,
      late_fee_rate_error: null,
      late_fee_period_days: 1,
      has_grace_period: false,
      grace_period_days: 0,
      grace_period_status: 0,
      grace_period_error: "",
      has_passkey: false,
      passkey: "",
      passkey_status: 0,
      passkey_error: "",
      random_passkey_length: 6,
      show_confirmation: false,
      sending: false,
      custom_identifier_1: "",
      custom_identifier_2: "",
      ref_preference: 0,
      remember_viewers: true,
      hide_at_completion: false,
      show_payid: true,
      accounts: JSON.parse(this.props.accounts),
      selected_account_index : 0,
      selected_series: null,
      typed_series_code: "",
      typed_series_code_status: 0,
      typed_series_code_message: "",
      messages: []
    };

    this.on_type_title = this.on_type_title.bind(this);
    this.on_blur_title = this.on_blur_title.bind(this);
    this.on_type_amount = this.on_type_amount.bind(this);
    this.on_focus_amount = this.on_focus_amount.bind(this);
    this.on_blur_amount = this.on_blur_amount.bind(this);
    this.is_valid_amount_input = this.is_valid_amount_input.bind(this);
    this.amount_to_formatted_string = this.amount_to_formatted_string.bind(this);
    this.on_type_description = this.on_type_description.bind(this);
    this.on_type_billing_name = this.on_type_billing_name.bind(this);
    this.on_blur_billing_name = this.on_blur_billing_name.bind(this);
    this.on_type_billing_email = this.on_type_billing_email.bind(this);
    this.on_blur_billing_email = this.on_blur_billing_email.bind(this);
    this.on_focus_billing_email = this.on_focus_billing_email.bind(this);
    this.on_change_date_accrued = this.on_change_date_accrued.bind(this);
    this.on_blur_date_accrued = this.on_blur_date_accrued.bind(this);
    this.on_change_due_at = this.on_change_due_at.bind(this);
    this.on_blur_due_at = this.on_blur_due_at.bind(this);
    this.on_type_late_amount = this.on_type_late_amount.bind(this);
    this.on_focus_late_amount = this.on_focus_late_amount.bind(this);
    this.on_blur_late_amount = this.on_blur_late_amount.bind(this);
    this.on_type_grace_period = this.on_type_grace_period.bind(this);
    this.on_blur_grace_period = this.on_blur_grace_period.bind(this);
    this.on_focus_grace_period = this.on_focus_grace_period.bind(this);
    this.random_passkey = this.random_passkey.bind(this);
    this.on_type_passkey = this.on_type_passkey.bind(this);
    this.on_focus_passkey = this.on_focus_passkey.bind(this);
    this.on_blur_passkey = this.on_blur_passkey.bind(this);
    this.add_message = this.add_message.bind(this);
    this.remove_message = this.remove_message.bind(this);
    this.get_series = this.get_series.bind(this);
    this.can_proceed = this.can_proceed.bind(this);
    this.create_invoice = this.create_invoice.bind(this);

    this.MINIMUM_TITLE_LENGTH = 5;
    this.MAXIMUM_TITLE_LENGTH = 30;
    this.MINIMUM_AMOUNT = 100;
    this.MAXIMUM_AMOUNT = 1000000000;
    this.MINIMUM_BILLING_NAME_LENGTH = 5;
    this.MAXIMUM_BILLING_NAME_LENGTH = 30;
    this.LATE_FEE_FREQUENCIES = [0,1,7,-1];
    this.LATE_FEE_FREQUENCY_TO_DESCRIPTION = {'-1': "Every month", '0':"Once-off", '1':"Daily", '7':"Weekly"};
    this.MINIMUM_LATE_AMOUNT = 1;
    this.MAXIMUM_LATE_AMOUNT = 100000;
    this.MAXIMUM_DESCRIPTION_LENGTH = 500;
    this.MINIMUM_GRACE_PERIOD = 0;
    this.MAXIMUM_GRACE_PERIOD = 14;
    this.MINIMUM_PASSKEY_LENGTH = 4;
    this.MAXIMUM_PASSKEY_LENGTH = 16;
    this.CUSTOM_REFERENCE_LENGTH_LIMIT = 128;

    this.MESSAGE_TYPE_TO_COLOUR = {'danger':'red','success':'green','info':'blue'};
    this.MESSAGE_TYPE_TO_ICON = {'danger':'circle-exclamation','warning':'circle-exclamation','info':'circle-info','success':'check'};

  }

  componentDidMount(){
    this.random_passkey();
    console.log(this.state);
  }

  on_type_amount(e){
    const typed = e.target.value;
    /*if (typed === '.' || /^\d{0,3}(?:\.\d{1,2})?$|^\d{1,3}(?:\.\d{0,1})?$|^\d{1}(?:\.\d{0,2})?$|^\.\d{1,3}$/.test(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }*/
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }
  }

  on_focus_amount(){
    this.setState({
      typed_amount: this.state.typed_amount.replaceAll('$','').replaceAll(',',''),
      typed_amount_status: 1
    }, () => {
      document.getElementById('amount-input').select();
    });
    setTimeout(() => {
      document.getElementById('amount-input').select();
    }, 50);
  }

  on_blur_amount(){
    let amount = this.state.typed_amount.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
    if (this.state.typed_amount === '.'){
      amount = 0;
    }
    const str = String(amount / 100);
    console.log("Amount: "+amount);
    this.setState({
      //typed_amount: '$' + (Number(amount) < 100 ? '0.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+String(amount) : amount.slice(0, amount.length-2)+'.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+Number(amount.slice(-2))),
      typed_amount: '$' + this.amount_to_formatted_string(amount),
      displayed_amount: '$' + this.amount_to_formatted_string(amount),
      amount: amount,
      typed_amount_status: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? 3 : 2,
      typed_amount_error: amount < this.MINIMUM_AMOUNT || amount > this.MAXIMUM_AMOUNT ? "The amount must be between $"+(this.MINIMUM_AMOUNT/100)+" and $"+(this.MAXIMUM_AMOUNT/100000000)+" million." : null
    });
  }

  amount_to_formatted_string(amount){
    const str = String(amount / 100);
    return Number(str.split('.')[0]).toLocaleString() + '.' + (str.includes('.') ? str.split('.')[1] : '') + String(amount % 10 === 0 ? '0' : '') + String(amount % 100 === 0 ? '0' : '');
  }

  on_type_title(e){
    const typed = e.target.value;
    if (typed.length < this.MAXIMUM_TITLE_LENGTH){
      this.setState({
        typed_title: typed
      });
    }
  }

  on_blur_title(){
    const typed = this.state.typed_title.trim();
    if (typed.length < this.MINIMUM_TITLE_LENGTH || typed.length > this.MAXIMUM_TITLE_LENGTH){
      this.setState({
        typed_title: typed,
        typed_title_status: 3,
        typed_title_error: "Title must be between "+String(this.MINIMUM_TITLE_LENGTH)+" and "+String(this.MAXIMUM_TITLE_LENGTH)+" characters."
      });
    } else {
      this.setState({
        typed_title: typed,
        typed_title_status: 2,
        typed_title_error: null
      });
    }
  }

  is_valid_amount_input(input) {
    console.log("testing "+input);
    if (input === "." || input === "") {
        return true;
    }
    const parts = input.split(".");
    if (parts.length === 1){
      return !isNaN(parts[0]);
    } else if (parts.length > 2){
      return false;
    }
    return !isNaN(parts[0]) && !isNaN(parts[1]) && parts[1].length <= 2;
  }

  on_type_description(e){
    const typed = e.target.value;
    if (typed.length <= this.MAXIMUM_DESCRIPTION_LENGTH){
      this.setState({
        typed_description: typed
      });
    }
  }

  on_type_billing_name(e){
    const typed = e.target.value;
    console.log(typed);
    if (typed.length < this.MAXIMUM_BILLING_NAME_LENGTH && /^[a-zA-Z0-9\s\-']*$|^$/.test(typed)){
      this.setState({
        billing_name: typed
      });
    }
  }

  on_blur_billing_name(){
    const typed = this.state.billing_name.trim();
    if (typed.length < this.MINIMUM_BILLING_NAME_LENGTH || typed.length > this.MAXIMUM_BILLING_NAME_LENGTH){
      this.setState({
        billing_name_status: 3,
        billing_name_error: "Name must be A-Z, spaces, and "+String(this.MINIMUM_BILLING_NAME_LENGTH)+"-"+String(this.MAXIMUM_BILLING_NAME_LENGTH)+" characters.",
        billing_name: typed
      });
    } else {
      this.setState({
        billing_name_status: 2,
        billing_name_error: "",
        billing_name: typed
      });
    }
  }

  on_type_billing_email(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~@.]*$/.test(typed)){
      this.setState({
        billing_email: typed
      });
    }
  }

  on_blur_billing_email(){
    if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(this.state.billing_email)){
      this.setState({
        billing_email_status: 3,
        billing_email_error: "You must enter a valid email."
      });
    } else {
      this.setState({
        billing_email_status: 2,
        billing_email_error: ""
      });
    }
  }

  on_focus_billing_email(){
    this.setState({
      billing_email_status: 1,
      billing_email_error: null
    });
  }

  on_change_date_accrued(e){
    this.setState({
      date_accrued_at: e.target.value
    });
  }

  on_blur_date_accrued(){
    //const diff = Date.now() - (new Date(this.state.date_accrued_at));
    const diff = Date.now() - (new Date(this.state.date_accrued_at)) - (new Date()).getTimezoneOffset()*60000;
    if (false && diff < 0){ // allow to be in the future
      this.setState({
        date_accrued_status: 3,
        date_accrued_error: "The accrued date cannot be in the future."
      });
    } else {
      this.setState({
        date_accrued_status: 2,
        date_accrued_error: null
      });
    }
  }

  on_change_due_at(e){
    this.setState({
      due_at: e.target.value
    });
  }

  on_blur_due_at(){
    const diff = Date.now() - (new Date(this.state.due_at));// - (new Date()).getTimezoneOffset()*60000;
    if (diff > 0){  // 86400000
      this.setState({
        due_at_status: 3,
        due_at_error: "Due date cannot be in the past."
      });
    } else {
      this.setState({
        due_at_status: 2,
        due_at_error: null
      });
    }
  }


  on_type_late_amount(e){
    const typed = e.target.value;
    /*if (typed === '.' || /^\d{0,3}(?:\.\d{1,2})?$|^\d{1,3}(?:\.\d{0,1})?$|^\d{1}(?:\.\d{0,2})?$|^\.\d{1,3}$/.test(typed) || typed.length === 0){
      this.setState({
        typed_amount: typed
      });
    }*/
    if (typed === '.' || this.is_valid_amount_input(typed) || typed.length === 0){
      this.setState({
        typed_late_fee_rate: typed
      });
    }
  }

  on_focus_late_amount(){
    this.setState({
      typed_late_fee_rate: this.state.typed_late_fee_rate.replaceAll('$','').replaceAll(',',''),
      late_fee_rate_status: 1
    }, () => {
      document.getElementById('late-amount-input').select();
    });
    setTimeout(() => {
      document.getElementById('late-amount-input').select();
    }, 50);
  }

  on_blur_late_amount(){
    let amount = this.state.typed_late_fee_rate.split('.').map((x,i) => i === 0 ? Number(x) * 100 : Number(x[0])*10+(x[1]===undefined ? 0 : Number(x[1]))).reduce((p,a) => p+a, 0);
    if (this.state.typed_late_fee_rate === '.'){
      amount = 0;
    }
    const str = String(amount / 100);
    console.log("Amount: "+amount);
    this.setState({
      //typed_amount: '$' + (Number(amount) < 100 ? '0.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+String(amount) : amount.slice(0, amount.length-2)+'.'+(Number(amount.slice(-2)) < 10 ? '0' : '')+Number(amount.slice(-2))),
      typed_late_fee_rate: '$' + this.amount_to_formatted_string(amount),
      displayed_late_fee_rate: '$' + this.amount_to_formatted_string(amount),
      late_fee_rate: amount,
      late_fee_rate_status: amount < this.MINIMUM_LATE_AMOUNT || amount > this.MAXIMUM_LATE_AMOUNT ? 3 : 2,
      late_fee_rate_error: amount < this.MINIMUM_LATE_AMOUNT || amount > this.MAXIMUM_LATE_AMOUNT ? "The amount must be between $"+this.amount_to_formatted_string(this.MINIMUM_LATE_AMOUNT)+" and $"+this.amount_to_formatted_string(this.MAXIMUM_LATE_AMOUNT) : null
    });
  }

  on_type_grace_period(e){
    const typed = e.target.value;
    if (!isNaN(typed)){
      this.setState({
        grace_period_days: Number(typed)
      });
    }
  }

  on_blur_grace_period(){
    this.setState({
      grace_period_status: this.state.grace_period_days < this.MINIMUM_GRACE_PERIOD || this.state.grace_period_days > this.MAXIMUM_GRACE_PERIOD ? 3 : 2,
      grace_period_error: this.state.grace_period_days < this.MINIMUM_GRACE_PERIOD || this.state.grace_period_days > this.MAXIMUM_GRACE_PERIOD ? "Must be between "+String(this.MINIMUM_GRACE_PERIOD)+" and "+String(this.MAXIMUM_GRACE_PERIOD)+" days." : null
    });
  }

  on_focus_grace_period(){
    this.setState({
      grace_period_status: 1
    });
    setTimeout(() => {
      document.getElementById('grace-period-input').select();
    }, 50);
  }

  random_passkey(){
    // randpk
    fetch("/randpk?n="+String(this.state.random_passkey_length), {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      this.setState({
        passkey: json.random,
        passkey_status: 2,
        passkey_error: null
      });
    });
  }

  on_type_passkey(e){
    const typed = e.target.value;
    if (/^[a-zA-Z0-9_\-]+$/.test(typed) && typed.length < this.MAXIMUM_PASSKEY_LENGTH){
      this.setState({
        passkey: typed
      });
    }
  }

  on_focus_passkey(){
    this.setState({
      passkey_status: 1
    });
    setTimeout(() => {
      document.getElementById('passkey-input').select();
    }, 50);
  }

  on_blur_passkey(){
    this.setState({
      passkey_status: this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH ? 3 : 2,
      passkey_error: this.state.passkey.length < this.MINIMUM_PASSKEY_LENGTH ? "Passkey must be between "+String(this.MINIMUM_PASSKEY_LENGTH)+" and "+String(this.MAXIMUM_PASSKEY_LENGTH)+" characters." : null
    });
  }

  get_series(){
    fetch("/api/ser/"+this.state.typed_series_code+"/admin", {
      method: 'GET',
      credentials: 'include'
    }).then( (response) => {
      if (response.ok){
        return response.json();
      }
      this.setState({
        typed_series_code_status: 3,
        typed_series_code_message: "Could not match to a Series"
      })
      //throw new Error('Request fail');
    }).then(json => {
      if (json.series != null){
        this.setState({
          selected_series: json.series,
          typed_series_code_status: 2,
          typed_series_code_message: "Successfully matched to a Series"
        });
      }
    });
  }

  can_proceed(){
    return [0,2].includes(this.state.typed_title_status) && 
      [0,2].includes(this.state.typed_amount_status) &&
      this.state.billing_name_status === 2 &&
      (!this.state.include_email || this.state.billing_email_status === 2) &&
      [0,2].includes(this.state.date_accrued_status) &&
      (!this.state.has_late_fees || [0,2].includes(this.state.late_fee_rate_status)) &&
      (!this.state.has_grace_period || [0,2].includes(this.state.grace_period_status)) &&
      (!this.state.has_passkey || [0,2].includes(this.state.passkey_status)) &&
      this.state.accounts.length > 0;
  }

  create_invoice(){
    this.setState({
      sending: true
    });
    const body = {
      invoice: {
        amount: this.state.amount,
        has_due_date: this.state.has_due_date,
        due_at: this.state.has_due_date ? this.state.due_at + "+"+String(Math.round((-100.0*(new Date()).getTimezoneOffset() / 60.0))) : null,//this.state.due_at + " 00:00:00 +"+String(Math.round((-100.0*(new Date()).getTimezoneOffset() / 60.0))) : null,
        title: this.state.typed_title,
        description: this.state.typed_description,
        billing_name: this.state.billing_name,
        billing_email: this.state.include_email ? this.state.billing_email : null,
        date_accrued_at: this.state.date_accrued_at,
        privacy_status: this.state.has_passkey ? 1 : 0,
        passkey_ciphertext: this.state.passkey,
        custom_code: this.state.custom_identifier_1,
        //remember_viewers: this.state.remember_viewers,
        ref_preference: this.state.ref_preference,
        hide_at_completion: this.state.hide_at_completion,
        show_payid: this.state.show_payid,
        account_id: this.state.accounts[this.state.selected_account_index].id,
        series_id: this.state.selected_series === null ? null : this.state.selected_series.id
      },
      tags: [this.state.custom_identifier_1].filter((c,i) => c != null && c != undefined && c.length >= 4),
      account_internal_code: this.state.accounts[this.state.selected_account_index].internal_code
    }
    fetch("/invoices", {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': this.props.auth_token
      },
      body: JSON.stringify(body)
    }).then( (response) => {
      if (response.ok || true){
        return response.json();
      }
      throw new Error('Request fail');
    }).then(json => {
      console.log("Results returned");
      console.log(json);
      this.setState({
        sending: false
      });
      if (json.success){
        location.href = "/invoice/"+json.invoice_code+"/admin";
      } else {
        this.add_message("Failed to create Invoice object. Errors: "+json.errors.join("; "),'danger');
      }
    });
  }

  remove_message(id){
    let messages = this.state.messages;
    let new_messages = [];
    let i;
    for (i = 0 ; i < messages.length ; i++){
      if (messages[i].id != id){
        new_messages.push(messages[i]);
      }
    }
    this.setState({
      messages: new_messages
    });
  }

  add_message(message, type){
    const id = Math.floor(Math.random()*1000000);
    let messages = this.state.messages;
    messages.push({
      content: message,
      type: type,
      id: id
    });
    this.setState({
      messages: messages
    });
    setTimeout(() => {
      this.remove_message(id);
    }, 5000);
  }

  render () {
    console.log(this.state);
    return (
      <div className="form-section">

        {this.state.messages.map(
          (message, index) =>
          <div className={"flash flash-"+message.type} key={index}>
            <table><tbody>
              <tr>
                <td style={{fontSize: '18px'}}>
                  <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                </td>
                <td>
                  {message.content}
                </td>
              </tr>
            </tbody></table>
          </div>
        )}

        {this.state.show_confirmation ? 
          <Overlay on_cancel={() => this.setState({show_confirmation: false})}>
            <div>
              {this.state.messages.map(
                (message, index) =>
                <div className={"flash flash-"+message.type} key={index}>
                  <table><tbody>
                    <tr>
                      <td style={{fontSize: '18px'}}>
                        <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON[message.type]}></i>
                      </td>
                      <td>
                        {message.content}
                      </td>
                    </tr>
                  </tbody></table>
                </div>
              )}
            
              <h3 style={{color: 'grey'}}>
                Confirm <span style={{color: 'black'}}>{this.state.typed_title}</span>
              </h3>
              <table id="confirmation-table"><tbody>
                <tr>
                  <td>
                    Amount
                  </td>
                  <td>
                    <b>{this.state.displayed_amount}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Description
                  </td>
                  <td>
                    {this.state.typed_description.length > 100 ? this.state.typed_description.slice(0,100)+"..." : this.state.typed_description}
                  </td>
                </tr>
                <tr>
                  <td>
                    Payor name
                  </td>
                  <td>
                    <b>{this.state.billing_name}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    Payor email
                  </td>
                  <td>
                    {this.state.include_email ? this.state.billing_email : "Not included"}
                  </td>
                </tr>
                <tr>
                  <td>
                    Date accrued
                  </td>
                  <td>
                    {this.state.date_accrued_at}
                  </td>
                </tr>
                <tr>
                  <td>
                    Due date
                  </td>
                  <td>
                    {this.state.has_due_date ? this.state.due_at : "No due date"}
                  </td>
                </tr>
                {/*<tr>
                  <td>
                    Late fees
                  </td>
                  <td>
                    {this.state.has_late_fees ? this.state.displayed_late_fee_rate + this.LATE_FEE_FREQUENCY_TO_DESCRIPTION[this.state.late_fee_period_days].toLowerCase() : "No late fees"}
                  </td>
                </tr>*/}
                {this.state.has_late_fees ? <tr>
                  <td>
                    Grace period
                  </td>
                  <td>
                    {this.state.has_grace_period ? this.state.grace_period_days + " days" : "No grace period"}
                  </td>
                </tr> : null}
                <tr>
                  <td>
                    Passkey
                  </td>
                  <td>
                    {this.state.has_passkey ? this.state.passkey : "No passkey"}
                  </td>
                </tr>
              </tbody></table>
              <button className="std-button" onClick={() => this.create_invoice()}>
                Confirm
              </button>
              <button className="std-button" onClick={() => this.setState({show_confirmation: false})}>
                Cancel
              </button>
            </div>
          </Overlay> : null}
        
        <table style={{width: '100%'}}><tbody>
          <tr>
            <td>
            <h2>
              {this.state.typed_title}
            </h2>
            </td>
            <td style={{textAlign: 'right'}}>
              <h2 style={{color: 'grey'}}>
                {this.state.displayed_amount}
              </h2>
            </td>
          </tr>
        </tbody></table>
        
        <div className="form-intra-section">
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_title} value={this.state.typed_title} onBlur={this.on_blur_title} status={this.state.typed_title_status} note={this.state.typed_title_status === 3 ? this.state.typed_title_error : null} label="Title of the invoice" />
          </div><br />
          <div className="myinput-wrapper-centre">
            <MyInput onChange={this.on_type_amount} value={this.state.typed_amount} onFocus={this.on_focus_amount} onBlur={this.on_blur_amount} status={this.state.typed_amount_status} note={this.state.typed_amount_status === 3 ? this.state.typed_amount_error : null} label="Amount you wish to invoice" id="amount-input" style={{textAlign: 'right'}} />
          </div><br />
          <div className="myinput-wrapper-centre">
            <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
              Description
            </div>
            <textarea onChange={this.on_type_description} value={this.state.typed_description} className="standard-textarea" />
            <div style={{color: 'grey', fontSize: '13px', marginBottom: '4px', textAlign: 'left'}}>
              {this.MAXIMUM_DESCRIPTION_LENGTH - this.state.typed_description.length}
            </div>
          </div>
        </div>

        <div>
          <h3>
            Deposit Account
          </h3>

          {this.state.accounts.length === 0 ? 
            <div className={"flash flash-warning"}>
              <table><tbody>
                <tr>
                  <td style={{fontSize: '18px'}}>
                    <i className={"fa-solid fa-"+this.MESSAGE_TYPE_TO_ICON['warning']}></i>
                  </td>
                  <td>
                    You need to add at least one Account to be able to create any Invoice, Customer or Fund object. You can link an Account by going to Consents under Bank Accounts.
                  </td>
                </tr>
              </tbody></table>
            </div> : null}

          <p style={{color: 'grey', fontSize: '14px'}}>
            This is the account that the payor is told to deposit the funds into.
          </p>
          {this.state.accounts.map(
            (account, index) =>
            <div className={this.state.selected_account_index === index ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({selected_account_index: index})} key={index}>
              <table><tbody>
                <tr>
                  <td>
                    <div>
                      <div />
                    </div>
                  </td>
                  <td>
                    <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                      {account.name}
                    </div>
                    <table style={{color: 'grey', fontSize: '16px', fontFamily: 'Roboto Mono'}}><tbody>
                      <tr>
                        <td style={{}}>
                          BSB:{account.bsb}
                        </td>
                        <td style={{}}>
                          Acct:{account.account_number}
                        </td>
                      </tr>
                    </tbody></table>
                  </td>
                </tr>
              </tbody></table>
            </div>
          )}
        </div>

        <div>
          <h3>
            Payor
          </h3>
          <table className="toggle-table"><tbody>
            <tr>
              <td></td>
              <td>
                <h4>
                  Payor name
                </h4>
                <p>
                  This could be an individual or an entity.
                </p>
              </td>
              <td>
              <MyInput onChange={this.on_type_billing_name} value={this.state.billing_name} status={this.state.billing_name_status} onBlur={this.on_blur_billing_name} label="Payor name" note={this.state.billing_name_status === 3 ? this.state.billing_name_error : null} />
              </td>
            </tr>
          </tbody></table>
          <table className="toggle-table"><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({include_email: v})} init_value={this.state.include_email} />
              </td>
              <td>
                <h4>
                  Include email
                </h4>
                <p>
                  We will send a copy of this invoice to this address.
                </p>
              </td>
              {this.state.include_email ? 
              <td>
                <MyInput onChange={this.on_type_billing_email} value={this.state.billing_email} status={this.state.billing_email_status} onFocus={this.on_focus_billing_email} onBlur={this.on_blur_billing_email} label="Payor email" note={this.state.billing_email_status === 3 ? this.state.billing_email_error : null} />
              </td> : null}
            </tr>
          </tbody></table> 
          {this.state.include_email && false ? 
            <MyInput onChange={this.on_type_billing_email} value={this.state.billing_email} status={this.state.billing_email_status} onFocus={this.on_focus_billing_email} onBlur={this.on_blur_billing_email} label="Payor email" note={this.state.billing_email_status === 3 ? this.state.billing_email_error : null} /> : null}
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Dates
          </h3>
          <table className="toggle-table"><tbody>
            <tr>
              <td></td>
              <td>
                <h4>
                  Date accrued
                </h4>
                <p>
                  Date when it was incurred.
                </p>
              </td>
              <td>
                <MyInput type="date" onChange={this.on_change_date_accrued} value={this.state.date_accrued_at} onBlur={this.on_blur_date_accrued} status={this.state.date_accrued_status} label="Last date when delivered." note={this.state.date_accrued_status === 3 ? this.state.date_accrued_error : null} />
              </td>
            </tr>
          </tbody></table>
          <br /><br />
          <table className="toggle-table" style={{marginTop: '5px'}}><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({has_due_date: v})} init_value={this.state.has_due_date} />
              </td>
              <td>
                <h4>
                  Has a due date
                </h4>
                <p>
                  This invoice will be overdue if not paid at the end of this date.
                </p>
              </td>
              {this.state.has_due_date ? 
              <td>
                <MyInput type="datetime-local" onChange={this.on_change_due_at} value={this.state.due_at} status={this.state.due_at_status} onBlur={this.on_blur_due_at} label="Due date" note={this.state.due_at_status === 3 ? this.state.due_at_error : null} />
                <p style={{color: "grey", fontSize: '13px'}}>
                  {String(new Date()).split(' ')[5]}
                </p>
              </td> : null}
            </tr>
            {this.state.has_due_date && false ? 
            <tr>
              <td>
                <Toggle on_toggle={(v) => this.setState({has_late_fees: v})} init_value={this.state.has_late_fees} />
              </td>
              <td>
                <h4>
                  Has late fees
                </h4>
                <p>
                  The amount of the invoice will increase by this amount per time period
                </p>
              </td>
              <td>
                {this.state.has_late_fees ? 
                <div>
                  <div>
                    <MyInput onChange={this.on_type_late_amount} value={this.state.typed_late_fee_rate} onFocus={this.on_focus_late_amount} onBlur={this.on_blur_late_amount} status={this.state.late_fee_rate_status} note={this.state.late_fee_rate_status === 3 ? this.state.late_fee_rate_error : null} label={"Late fee to be paid "+this.LATE_FEE_FREQUENCY_TO_DESCRIPTION[this.state.late_fee_period_days].toLowerCase()} id="late-amount-input" style={{textAlign: 'right'}} />
                  </div>
                  <div style={{marginTop: '5px'}}>
                    <table><tbody>
                      {this.LATE_FEE_FREQUENCIES.map(
                        (freq, index) =>
                          <tr key={index}>
                            <td style={{padding: '5px', width: '30px'}}>
                              {/*<input type="radio" checked={freq === this.state.late_fee_period_days} onClick={() => this.setState({late_fee_period_days: freq})} className="standard-radio" />*/}
                              <div className={freq === this.state.late_fee_period_days ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({late_fee_period_days: freq})}>
                                <div />
                              </div>
                            </td>
                            <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                              {this.LATE_FEE_FREQUENCY_TO_DESCRIPTION[freq]}
                            </td>
                          </tr>)}
                    </tbody></table>
                  </div>
                </div> : null}
              </td>
            </tr> : null}
            {this.state.has_late_fees ? 
            <tr>
              <td>
                <Toggle on_toggle={(v) => this.setState({has_grace_period: v})} init_value={this.state.has_grace_period} />
              </td>
              <td>
                <h4>
                  Has grace period
                </h4>
                <p>
                  Period after due date when late fees are not imposed (this will not be revealed to the payor).
                </p>
              </td>
              <td>
                {this.state.has_grace_period ? 
                <div>
                  <MyInput onChange={this.on_type_grace_period} value={this.state.grace_period_days} status={this.state.grace_period_status} onBlur={this.on_blur_grace_period} id="grace-period-input" note={this.state.grace_period_status === 3 ? this.state.grace_period_error : null} label="Days of grace" style={{textAlign: 'right'}} onFocus={this.on_focus_grace_period} />
                </div> : null}
              </td>
            </tr> : null}
          </tbody></table>
          {this.state.has_due_date && false ? 
            <MyInput type="date" onChange={this.on_change_due_at} value={this.state.due_at} status={this.state.due_at_status} onBlur={this.on_blur_due_at} label="Due date" /> : null}
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Privacy
          </h3>
          <table className="toggle-table-general"><tbody>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({has_passkey: v})} init_value={this.state.has_passkey} />
              </td>
              <td>
                <h3>
                  Has passkey
                </h3>
                <p>
                  A passkey in addition to a URL is required to view the invoice.
                </p>
              </td>
            </tr>
            {this.state.has_passkey ? 
            <tr>
              <td></td>
              <td>
                {this.state.has_passkey ? 
                  <div>
                    <MyInput onChange={this.on_type_passkey} value={this.state.passkey} status={this.state.passkey_status} id="passkey-input" onFocus={this.on_focus_passkey} onBlur={this.on_blur_passkey} label="Passkey" note={this.state.passkey_status === 3 ? this.state.passkey_error : null} />
                    <table><tbody>
                      <tr>
                        <td>
                          <button className="std-button" onClick={this.random_passkey} style={{marginTop: '10px'}}>
                            Random
                          </button>
                        </td>
                        <td style={{paddingTop: '15px'}}>
                          <div>
                            <input type="range" min="4" max="16" onChange={(e) => this.setState({random_passkey_length: Number(e.target.value)})} value={String(this.state.random_passkey_length)} style={{width: '100%', margin: 'auto'}} />
                          </div>
                          <div style={{textAlign: 'center', fontSize: '13px', color: 'grey'}}>
                            {this.state.random_passkey_length} characters
                          </div>
                        </td>
                      </tr>
                    </tbody></table>
                  </div> : null}
                {/*this.state.has_passkey ? 
                <div>
                  <table><tbody>
                    <tr>
                      <td style={{padding: '5px', width: '30px'}}>
                        <div className={freq === this.state.late_fee_period_days ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({late_fee_period_days: freq})}>
                          <div />
                        </div>
                      </td>
                      <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                        <MyInput onChange={this.on_type_passkey} />
                      </td>
                    </tr>
                  </tbody></table>
                </div> : null*/}
              </td>
            </tr> : null}
            {/*this.state.has_passkey ? 
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({remember_viewers: v})} init_value={this.state.remember_viewers} />
              </td>
              <td>
                <h3>
                  Remember viewer
                </h3>
                <p>
                  Remember a viewer's device so that they don't need to enter the passkey again. If you require the highest level of security, ensure this is unselected.
                </p>
              </td>
              <td>
                
              </td>
              </tr> : null*/}
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({hide_at_completion: v})} init_value={this.state.hide_at_completion} />
              </td>
              <td>
                <h3>Hide at completion</h3>
                <p>
                  Stop the visibility of the invoice once it has been paid or otherwise completed.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Checkbox on_toggle={(v) => this.setState({show_payid: v})} init_value={this.state.show_payid} />
              </td>
              <td>
                <h3>Show PayID</h3>
                <p>
                  Show PayID as a payment method.
                </p>
              </td>
            </tr>
          </tbody></table>
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Reference
          </h3>
          <table className="toggle-table"><tbody>
            <tr>
              <td>

              </td>
              <td>
                <h4>
                  Internal Reference
                </h4>
                <p>
                  You can add an internal reference, such as an invoice number, order number or serial number.
                </p>
              </td>
              <td>
                <div>
                  <MyInput onChange={(e) => this.setState({custom_identifier_1: e.target.value})} value={this.state.custom_identifier_1} label="Custom identifier" />
                </div>
                {/*<div>
                  <MyInput onChange={(e) => this.setState({custom_identifier_2: e.target.value})} value={this.state.custom_identifier_2} label="Custom identifier 2" />
                </div>*/}
              </td>
            </tr>
          </tbody></table>
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Associated Series
          </h3>
          <table><tbody>
            <tr>
              <td style={{width: '150px', fontSize: '14px', color: 'grey'}}>
                Selected Series:
              </td>
              <td style={{width: '250px', fontSize: '14px'}}>
                {this.state.selected_series === null ? 
                  <div>
                    No Series selected
                  </div> : 
                  <div>
                    {this.state.selected_series.title} <span style={{color: 'grey'}}>({this.state.selected_series.code})</span>
                  </div>}
              </td>
              <td>
                <button onClick={() => this.setState({selected_series: null, typed_series_code: "", typed_series_code_message: "", typed_series_code_status: 0})} className="button-blue">
                  Clear
                </button>
              </td>
            </tr>
          </tbody></table>
          <h4>
            Add Series by code
          </h4>
          <div className="myinput-wrapper-centre">
            <MyInput onChange={(e) => this.setState({typed_series_code: e.target.value})} value={this.state.typed_series_code} onFocus={() => this.setState({typed_series_code_status: 1, typed_series_code_message: ""})} status={this.state.typed_series_code_status} note={this.state.typed_series_code_message} label="Series code" />
            <br />
            <button className="button-blue" onClick={this.get_series}>
              Add
            </button>
            <button className="button-blue" onClick={() => {
              window.open("/series/search",'_blank');
            }}>
              Search <i className="fa-solid fa-arrow-up-right-from-square"></i>
            </button>
          </div>
        </div>

        <div style={{marginTop: '40px'}}>
          <h3>
            Customer Reference Preferences
          </h3>
          <p style={{color: 'grey', fontSize: '14px'}}>
            This will determine whether a single reference is generated, or one for each email instance provided by a customer.
          </p>

          <div className={this.state.ref_preference === 0 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 0})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Single Reference Code
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payor(s) will not be able to obtain a unique reference.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className={this.state.ref_preference === 1 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 1})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Allow email-unique references
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payors can register an email and obtain a unique reference for payment.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>
          <div className={this.state.ref_preference === 2 ? "selection-box-selected" : "selection-box"} onClick={() => this.setState({ref_preference: 2})}>
            <table><tbody>
              <tr>
                <td>
                  <div>
                    <div />
                  </div>
                </td>
                <td>
                  <div style={{color: 'black', fontSize: '16px', fontWeight: 'bold'}}>
                    Require email-unique references
                  </div>
                  <p style={{color: "grey", fontSize: "14px"}}>
                    Payors must register an email and obtain a unique reference for payment.
                  </p>
                </td>
              </tr>
            </tbody></table>
          </div>

          {/*<table className="toggle-table"><tbody>
            <tr>
              <td>

              </td>
              <td>
                <h4>
                  Customer Reference
                </h4>
                <p>
                  This will determine whether a single reference is generated, or one for each email instance provided by a customer.
                </p>
              </td>
              <td>
                <table><tbody>
                  <tr>
                    <td style={{padding: '5px', width: '30px'}}>
                      <div className={this.state.ref_preference === 0 ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({ref_preference: 0})}>
                        <div />
                      </div>
                    </td>
                    <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                      <b>Single reference code</b>
                      <p>
                        Payor(s) will not be able to obtain a unique reference.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px', width: '30px'}}>
                      <div className={this.state.ref_preference === 1 ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({ref_preference: 1})}>
                        <div />
                      </div>
                    </td>
                    <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                      <b>Allow email-unique references</b>
                      <p>
                        Payors can register an email and obtain a unique reference for payment.
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td style={{padding: '5px', width: '30px'}}>
                      <div className={this.state.ref_preference === 2 ? "standard-radio-checked" : "standard-radio"} onClick={() => this.setState({ref_preference: 2})}>
                        <div />
                      </div>
                    </td>
                    <td style={{fontSize: '14px', color: 'grey', padding: '5px', verticalAlign: 'center'}}>
                      <b>Require email-unique references</b>
                      <p>
                        Payors must register an email and obtain a unique reference for payment.
                      </p>
                    </td>
                  </tr>
                </tbody></table>
              </td>
            </tr>
            </tbody></table>*/}
        </div>

        {this.can_proceed() ? <button className="std-button" onClick={() => this.setState({show_confirmation: true})}>
          Proceed
        </button> : null}
      </div>
    );
  }
}

export default NewInvoice
